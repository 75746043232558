import { createSuperAgent } from './requestUtils';
import { browserHistory } from 'react-router';
import { AVAILABLE_LANGUAGES, AVAILABLE_LANGUAGES_FI, AVAILABLE_LANGUAGES_NO } from '../common/constants';
import i18nCountries from 'i18n-iso-countries';
import i18nLanguages from '@cospired/i18n-iso-languages';

// If additional languages are added then the corresponding locales also need to be registered
i18nCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
i18nCountries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
i18nCountries.registerLocale(require('i18n-iso-countries/langs/fi.json'));
i18nCountries.registerLocale(require('i18n-iso-countries/langs/no.json'));
i18nLanguages.registerLocale(require('@cospired/i18n-iso-languages/langs/en.json'));
i18nLanguages.registerLocale(require('@cospired/i18n-iso-languages/langs/sv.json'));
i18nLanguages.registerLocale(require('@cospired/i18n-iso-languages/langs/fi.json'));
i18nLanguages.registerLocale(require('@cospired/i18n-iso-languages/langs/no.json'));

// TODO: Move the large logos to common assets too
const logoVariants = {
  black: {
    default: require('../common/assets/logo_black.png'),
    fi: require('../common/assets/logo_black_fi.png'),
    no: require('../common/assets/logo_black_no.png'),
  },
  large: {
    default: require('../customer/SelectLibrary/assets/logo_large.png'),
    fi: require('../customer/SelectLibrary/assets/logo_large_fi.png'),
    no: require('../customer/SelectLibrary/assets/logo_large_no.png'),
  },
};

export const MAX_DELAY_BEFORE_LANGUAGE_IS_SET = 2000;

export const LOC_USER_LANGUAGE = 'USER_LANGUAGE';

export const DEFAULT_COUNTRY = 'se';

export const DEFAULT_LANGUAGE = 'sv';

let languagesWithISO = null; // to be properly initialized in function initLanguagesWithISO as soon as they are needed
let languagesWithISOKeys = null;
let countriesWithISO = null; // to be properly initialized in function initCountriesWithISO as soon as they are needed
let countriesWithISOKeys = null;
// src/customer/SelectLibrary/assets

export const i18nLogo = (variant) => {
  const iso = getCurrentCountry();
  variant = variant || 'large';
  console.log(variant, iso);
  return logoVariants[variant][iso] || logoVariants[variant]['default'] || logoVariants['large']['default'];
};

export const fetchTranslations = (callback) => {
  createSuperAgent({ path: '/i18n/translation/get_translations' }).end((err, res) => {
    if (!err && res && res.body && res.body.success) {
      window.I18N_TRANSLATIONS = res.body.translations;
    }
    callback();
  });
};

export const errorMessages = new Map([
  [
    'sv',
    {
      title: 'Fel',
      body: 'Vi har för närvarande en driftstörning i tjänsten. Var god försök igen om en liten stund.',
    },
  ],
  [
    'no',
    {
      title: 'Feil',
      body: 'Vi opplever for tiden et driftsavbrudd. Prøv igjen om en liten stund.',
    },
  ],
  [
    'fi',
    {
      title: 'Väärä',
      body: 'Meillä on tällä hetkellä palvelukatkos. Yritä uudelleen hetken kuluttua.',
    },
  ],
  [
    'en',
    {
      title: 'Error',
      body: 'We are currently experiencing a service outage. Please try again in a little while.',
    },
  ],
]);

export function i18nErrorMessage() {
  const iso = getCurrentLanguage().toLowerCase();
  return errorMessages.get(iso) || errorMessages.get('en');
}

export const getCurrentCountry = () => {
  switch (window.location.hostname.split('.').pop()) {
    case 'no':
      return 'no';
    case 'fi':
      return 'fi';
    case 'se':
      return 'se';
    default:
      console.warn(`Unrecognized country code, defaulting to ${DEFAULT_COUNTRY}`);
      return DEFAULT_COUNTRY;
  }
};

export const getCurrentLanguage = (getDefaultIfNotSet = true) => {
  let availableLanguages = getAvailableLanguages();
  for (const availableLanguage of availableLanguages) {
    if (window.location.pathname && window.location.pathname.startsWith('/' + availableLanguage + '/')) {
      return availableLanguage;
    }
  }

  let localStorageLanguage = localStorage.getItem(LOC_USER_LANGUAGE);
  if (localStorageLanguage && availableLanguages.indexOf(localStorageLanguage) >= 0) {
    return localStorageLanguage;
  }

  return getDefaultIfNotSet ? getDefaultLanguage() : null;
};

export const getDefaultLanguage = () => {
  switch (getCurrentCountry()) {
    case 'no':
      return 'no';
    case 'fi':
      return 'fi';
    default:
      return DEFAULT_LANGUAGE;
  }
};

export const getAvailableLanguages = () => {
  switch (getCurrentCountry()) {
    case 'no':
      return AVAILABLE_LANGUAGES_NO;
    case 'fi':
      return AVAILABLE_LANGUAGES_FI;
    default:
      return AVAILABLE_LANGUAGES;
  }
};

export const getUrl = (countryConfig, type = 'default') => {
  if (!countryConfig) {
    console.error('CountryConfig not provided to getUrl');
    return '';
  }

  switch (type) {
    case 'press':
      return countryConfig.get('press_url') || '';
    case 'help':
      return countryConfig.get('help_url') || '';
    default:
      return countryConfig.get('default_url') || '';
  }
};

export const i18n = (keyword, replacements = null) => {
  if (window.I18N_TRANSLATIONS && window.I18N_TRANSLATIONS[keyword]) {
    let translation = window.I18N_TRANSLATIONS[keyword][getCurrentLanguage()];
    if (translation) {
      translation = translation
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/\n/g, '<br>');
      // NOTE: the replacements may contain HTML, hence they should be made after the above HTML escaping
      if (replacements) {
        for (const key in replacements) {
          translation = translation.split('{' + key + '}').join(replacements[key]);
        }
      }
      return translation;
    }
  }
  console.error(`No translation found for key: ${keyword}`);
  return keyword;
};

export const i18nValue = (item, key = 'name') => {
  if (!item) {
    return '';
  }
  const language = getCurrentLanguage();
  if (language === 'en') {
    key = `${key}_en`;
  }
  let value = typeof item.get === 'function' ? item.get(key) : item[key];
  if (!value && language !== 'en') {
    value = typeof item.get === 'function' ? item.get(`${key}_en`) : item[`${key}_en`];
  }
  return value;
};

export const i18nLanguageWithISO = (languageToTranslate, languageISO, currentLanguage) => {
  currentLanguage = currentLanguage || getCurrentLanguage();
  const res = i18nLanguages.getName(languageISO, currentLanguage);
  return res !== undefined ? res.charAt(0).toUpperCase() + res.slice(1) : undefined;
};

export const i18nCountryWithISO = (countryToTranslate, countryISO, currentLanguage) => {
  currentLanguage = currentLanguage || getCurrentLanguage();
  return i18nCountries.getName(countryISO, currentLanguage);
};

// export const getByISO = (type, name, language) => {
//   // language should not be the default here
//   const nameLowerCase = name && name.toLowerCase();
//   let objectWithISOToUse = null,
//     isoKeys = null;
//   if (type === 'country') {
//     initCountriesWithISO();
//     objectWithISOToUse = countriesWithISO;
//     isoKeys = countriesWithISOKeys;
//   } else {
//     initLanguagesWithISO();
//     objectWithISOToUse = languagesWithISO;
//     isoKeys = languagesWithISOKeys;
//   }
//   for (let i = 0; i < isoKeys.length; i++) {
//     if (objectWithISOToUse[isoKeys[i]][language].toLowerCase() === nameLowerCase) {
//       return isoKeys[i];
//     }
//   }
//   return '';
// };

export const i18nUrl = (url) => {
  return '/' + getCurrentLanguage() + url;
};

export const goToURL = (url) => {
  browserHistory.push(i18nUrl(url));
};

export const goToUrlAndRefresh = (url) => {
  window.location.href = i18nUrl(url);
};

export const i18nRedirect = (isCustomerPortal, isLibraryPortal) => {
  let path = window.location.pathname;

  if (path.match(new RegExp('^/(' + getAvailableLanguages().join('|') + ')$'))) {
    // for example: /fi -> /fi/
    window.location = path + '/';
    return;
  }

  if (path === '/lib' || path.match(new RegExp('^/(' + getAvailableLanguages().join('|') + ')/lib$'))) {
    // user trying to access lib portal without trailing slash
    path = '/to-be-replaced/lib/';
  } else if (path !== '/' && path.indexOf('/', 1) === -1) {
    // /something-without-slash -> /to-be-replaced/something-without-slash
    path = '/to-be-replaced' + path;
  }
  if (isLibraryPortal && path.match(new RegExp('^/(' + getAvailableLanguages().join('|') + ')/$'))) {
    window.location = path + 'lib/';
  } else if (path === '/' || !path.match(new RegExp('^/(' + getAvailableLanguages().join('|') + ')/'))) {
    // either redirect /lib or /lib/ to /[language code]/lib/ or /something to /[language code]/something
    redirectUserIfNoLanguageInURL(
      path === '/' ? (isLibraryPortal ? 'lib/' : '') : path.replace(/^\/[^/]+($|\/)/, ''),
      path !== '/'
    );
  }
};

export const redirectUserIfNoLanguageInURL = (endUrl = '', fullRedirect = false) => {
  const redirectUser = (url) => {
    if (fullRedirect) {
      window.location = url + (url.endsWith('/') ? '' : '/') + endUrl;
    } else {
      browserHistory.push(url + (url.endsWith('/') ? '' : '/') + endUrl);
    }
  };

  let localStorageLanguage = localStorage.getItem(LOC_USER_LANGUAGE);
  let availableLanguages = getAvailableLanguages();

  if (!localStorageLanguage || availableLanguages.indexOf(localStorageLanguage) < 0) {
    localStorage.setItem(LOC_USER_LANGUAGE, getDefaultLanguage());
  }
  redirectUser('/' + localStorage.getItem(LOC_USER_LANGUAGE) + '/');
};
