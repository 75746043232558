/**
 *
 * LibApp
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages for the library app. (e.g. navigation bar)
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { browserHistory } from 'react-router';
import { loadLibrarianData, setLibrarianData, logOut } from 'librarian/LibLogin/libLoginActions';
import LibHeader from './components/LibHeader/LibHeader';
import LoadingBar from 'common/components/LoadingBar/LoadingBar';
import { i18nUrl } from 'utils/i18nUtils';
import { initialLoginData } from 'app';
import ErrorPopup, { triggerErrorPopup } from 'common/err/ErrorPopup';

export const redirectIfNotLoggedIn = (librarian) => {
  if (!initialLoginData && !librarian) {
    // User has no active login-session in the backend and needs to log in
    browserHistory.push(i18nUrl('/lib/login'));
    return true;
  }
  return false;
};

class LibApp extends Component {
  constructor(props) {
    super(props);

    this.initialLoginDataUsed = false;
    this.state = {
      errorPopupOnError: true,
    };
  }

  componentWillMount() {
    if (!this.props.librarian) {
      // check if librarian is logged in when app loads
      if (!this.initialLoginDataUsed && initialLoginData) {
        this.props.setLibrarianData(initialLoginData);
        this.initialLoginDataUsed = true;
      }
    } else {
      this.props.loadLibrarianData();
    }
  }

  handleErrorPopupClose() {
    this.setState({ errorPopupOnError: false });
  }

  render() {
    return (
      <div>
        <Helmet
          title={(window.location.hostname.includes('staging') ? '[Staging] ' : '') + 'Bibliotek - Cineasterna'}
        />

        {this.state.errorPopupOnError && this.props.error && triggerErrorPopup(this.props.errorStatus) && (
          <ErrorPopup errorStatus={this.props.errorStatus} hide={() => this.handleErrorPopupClose()} />
        )}

        <LoadingBar />

        <LibHeader
          librarian={this.props.librarian || undefined}
          logout={this.props.logOut}
          attemptedLogoutResult={this.props.attemptedLogoutResult}
        />

        {React.Children.toArray(this.props.children)}
      </div>
    );
  }
}

LibApp.propTypes = {
  children: PropTypes.node,
};

function mapStateToProps(state) {
  return {
    librarian: state.get('libLoginReducers').get('librarian'),
    attemptedLogoutResult: state.get('libLoginReducers').get('attemptedLogoutResult'),
    error: state.getIn(['errorReducer', 'networkErrors', 'error']),
    errorStatus: state.getIn(['errorReducer', 'networkErrors', 'status']),
  };
}

export default connect(mapStateToProps, {
  loadLibrarianData,
  setLibrarianData,
  logOut,
})(LibApp);
