export const setError = (err, origin, result = {}) => {
  return {
    type: 'ERROR_SET',
    error: err,
    origin: origin,
    result: result,
  };
};

export const resetError = () => {
  return {
    type: 'ERROR_RESET',
  };
};
