import React, { Component } from 'react';
import { connect } from 'react-redux';
import { i18n } from 'utils/i18nUtils';
import { resetNetworkStatus } from 'middlewares/networkActions';
import { requestPasswordReset, PASSWORD_RESET_REQUESTED } from '../../authActions';
import { showLoginModal } from 'customer/App/components/Modal/modalActions';
import Button from 'common/components/Button/Button';
import Input from 'common/components/Input/Input';
import EmailIcon from 'common/assets/icons/EmailIcon';
import './ForgotPassword.scss';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
    };

    this.handleOnChangeEmail = this.handleOnChangeEmail.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.resetNetworkStatus(PASSWORD_RESET_REQUESTED);
  }

  handleOnChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  submit(e) {
    e.preventDefault();
    this.props.requestPasswordReset(this.state.email);
  }

  renderForm() {
    return (
      <form onSubmit={this.submit}>
        <div className="forgot-password-content">
          <p className="forgot-password-info">{i18n('PASSWORD_RECOVERY_HELP_MESSAGE')}</p>
          <Input
            className="email-input"
            type="email"
            placeholder={i18n('EMAIL')}
            icon={EmailIcon}
            value={this.state.email}
            onChange={this.handleOnChangeEmail}
            errorMessage={this.props.submitRequestStatus.error ? i18n('PASSWORD_RECOVERY_ERROR_MESSAGE') : null}
          />
          <Button variant="link" size="sm" onClick={this.props.showLoginModal}>
            {i18n('GO_BACK')}
          </Button>
        </div>
        <Button className="submit-button" type="submit" disabled={this.props.submitRequestStatus.pending}>
          {i18n('SEND')}
        </Button>
      </form>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="forgot-password-content">
        <p>{i18n('PASSWORD_RECOVERY_SUCCESS_MESSAGE')}</p>
        <Button variant="link" size="sm" onClick={this.props.showLoginModal}>
          {i18n('GO_BACK')}
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div className="forgot-password">
        {this.props.submitRequestStatus.success ? this.renderSuccessMessage() : this.renderForm()}
      </div>
    );
  }
}

ForgotPassword.defaultProps = {
  submitRequestStatus: {},
};

function mapStateToProps(state) {
  return {
    submitRequestStatus: state.get('networkReducers')[PASSWORD_RESET_REQUESTED],
  };
}

export default connect(mapStateToProps, {
  showLoginModal,
  requestPasswordReset,
  resetNetworkStatus,
})(ForgotPassword);
