import { createSuperAgent } from './requestUtils';

const IMDB_ORIGIN = 'http://www.imdb.com';
// OMDB API can be used to undirectly get information from IMDB
const OMDB_ORIGIN = window.location.protocol + '//www.omdbapi.com';

// storage used to save title information to avoid too many requests: should be called only one time
export const initImdbTemporaryStorage = () => {
  if (localStorage.getItem('IMDB_TEMPORARY_STORAGE')) {
    window.IMDB_TEMPORARY_STORAGE = localStorage.getItem('IMDB_TEMPORARY_STORAGE');
    if (window.IMDB_TEMPORARY_STORAGE) {
      try {
        window.IMDB_TEMPORARY_STORAGE = JSON.parse(window.IMDB_TEMPORARY_STORAGE);
        if (
          !window.IMDB_TEMPORARY_STORAGE || // check against too old timestamp
          window.IMDB_TEMPORARY_STORAGE.timestamp < parseInt(new Date().getTime() / 1000) - 3600 * 24
        ) {
          window.IMDB_TEMPORARY_STORAGE = undefined;
        }
      } catch (e) {
        window.IMDB_TEMPORARY_STORAGE = undefined;
      }
    }
  }
  window.IMDB_TEMPORARY_STORAGE = window.IMDB_TEMPORARY_STORAGE || {
    imdbTitles: {},
    timestamp: parseInt(new Date().getTime() / 1000),
  };
};

export const treatImdbId = (imdbId) => {
  return imdbId ? ((String(imdbId).startsWith('tt') ? '' : 'tt') + imdbId).trim().replace(/\//g, '') : '';
};

export const getImdbTitleUrl = (imdbId) => {
  return IMDB_ORIGIN + '/title/' + treatImdbId(imdbId) + '/';
};

export const getImdbTitleInfo = (imdbId, callback) => {
  // Not making OMDB API requests without API key.
  callback(null);
  return;

  // eslint-disable-next-line no-unreachable
  if (window.IMDB_TEMPORARY_STORAGE.imdbTitles[imdbId] !== undefined) {
    callback(window.IMDB_TEMPORARY_STORAGE.imdbTitles[imdbId]);
  } else {
    window.IMDB_TEMPORARY_STORAGE.imdbTitles[imdbId] = null; // initialy set to null and not undefined!
    createSuperAgent({ externalUrl: OMDB_ORIGIN, query: { i: treatImdbId(imdbId) } }).end((err, res) => {
      if (!err && res && res.body) {
        window.IMDB_TEMPORARY_STORAGE.imdbTitles[imdbId] = res.body;
        callback(res.body);
      } else {
        callback(null);
      }
      localStorage.setItem('IMDB_TEMPORARY_STORAGE', JSON.stringify(window.IMDB_TEMPORARY_STORAGE));
    });
  }
};

export const getImdbRating = (imdbId, callback) => {
  getImdbTitleInfo(imdbId, (title) => callback(title && title.imdbRating ? title.imdbRating : 'N/A'));
};

export const autoImdbRating = (title, updateTitleRating) => {
  if (title && title.get('auto_rating') && title.get('imdb_id')) {
    getImdbRating(title.get('imdb_id'), (rating) => {
      if (rating && rating !== 'N/A') {
        updateTitleRating({ titleRating: rating });
      }
    });
  }
};
