import * as ActionType from './userProfileActions';
import { TITLE_FAVOURITED, TITLE_UNFAVOURITED } from 'customer/Title/titleActions';
import Immutable from 'immutable';

let defaultState = Immutable.fromJS({
  favouriteTitles: [],
  loanedTitles: [],
});

export default function userProfileReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOADED_FAVOURITE_TITLES:
      return state.merge({ favouriteTitles: action.response.data });

    case ActionType.LOADED_LOANED_TITLES:
      return state.merge({ loanedTitles: action.response.data });

    case TITLE_FAVOURITED:
      return state.merge({
        favouriteTitles: state.get('favouriteTitles').map((t) => {
          if (t.get('title_id') === action.response.data.title_id) {
            return t.merge({ title: t.get('title').merge({ is_favourite: true }), id: action.response.data.id });
          }

          return t;
        }),
      });

    case TITLE_UNFAVOURITED:
      return state.merge({
        favouriteTitles: state.get('favouriteTitles').map((t) => {
          if (t.get('id') === action.response.data.favourite_id) {
            return t.merge({ title: t.get('title').merge({ is_favourite: false }) });
          }

          return t;
        }),
      });

    default:
      return state;
  }
}
