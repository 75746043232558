import * as ActionType from './selectLibraryActions';
import Immutable from 'immutable';
import { getCurrentLanguage } from '../../utils/i18nUtils';

let defaultState = Immutable.fromJS({
  libraries: [],
  userLibraries: [],
  newTitles: [],
  getStartedSteps: [],
  number_of_titles: 0,
});

export default function selectLibraryReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOADED_LIBRARIES:
      let { libraries, user_libraries, number_of_titles } = action.response;

      libraries = libraries.sort((a, b) => a.name.localeCompare(b.name, getCurrentLanguage()));

      const userLibraries = [];
      if (libraries.length > 0 && user_libraries && user_libraries.length > 0) {
        for (const userLibrary of user_libraries) {
          const library = libraries.find((lib) => userLibrary.library_id === lib.id);
          if (library) userLibraries.push(library);
        }
      }

      return state.merge({ libraries, userLibraries, number_of_titles });

    case ActionType.LOADED_NEW_TITLES:
      return state.merge({ newTitles: action.response });

    case ActionType.LOADED_GET_STARTED_STEPS:
      return state.merge({ getStartedSteps: action.response });

    default:
      return state;
  }
}
