import * as ActionType from './authActions';
import Immutable from 'immutable';
import { setCsrfTokenCookie } from 'utils/cookieUtils';

let defaultState = Immutable.fromJS({
  attemptedLoginResult: {},
  attemptedRegisterResult: {},
  attemptedLogoutResult: {},
  userData: null, // null means not logged in
});

export default function authReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.FETCHED_USER_DATA:
      return state.merge({
        userData: action.response && action.response.success ? action.response.user : null,
      });

    case ActionType.SET_USER_DATA:
      return state.merge({ userData: action.userData });

    case ActionType.LOGIN_ATTEMPTED:
      return state.merge({ attemptedLoginResult: action.response });

    case ActionType.REGISTER_ATTEMPTED:
      return state.merge({ attemptedRegisterResult: action.response });

    case ActionType.LOGOUT_ATTEMPTED:
      if (action.response && action.response.success) {
        setCsrfTokenCookie(action.response.new_csrftoken);
        return state.merge({
          attemptedLogoutResult: action.response,
          // reset user data and possible previous login/register attempts if user wants to re-login/register
          userData: null,
          attemptedRegisterResult: {},
          attemptedLoginResult: {},
        });
      }
      return state.merge({ attemptedLogoutResult: action.response });

    default:
      return state;
  }
}
