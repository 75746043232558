import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  MAX_DELAY_BEFORE_LANGUAGE_IS_SET,
  LOC_USER_LANGUAGE,
  getCurrentLanguage,
  getAvailableLanguages,
} from 'utils/i18nUtils';
import './languageSelector.scss';

export default class LanguageSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLanguage: getCurrentLanguage(false) || localStorage.getItem(LOC_USER_LANGUAGE),
      showShortLanguageInSelector: true,
    };
  }

  componentDidMount() {
    if (!this.state.selectedLanguage) {
      setTimeout(
        () => this.setState({ selectedLanguage: getCurrentLanguage() }),
        MAX_DELAY_BEFORE_LANGUAGE_IS_SET + 100
      ); // +100 for very unlikely overtime with javascript exec
    }
  }

  updateLanguage(newLanguage = null) {
    if (!newLanguage && this.refs.languageSelector) {
      this.refs.languageSelector.blur();
    }
    if (newLanguage) {
      localStorage.setItem(LOC_USER_LANGUAGE, newLanguage);
    } else {
      localStorage.setItem(LOC_USER_LANGUAGE, this.refs.languageSelector.value);
    }
    window.location =
      '/' + (newLanguage || this.refs.languageSelector.value) + (window.location.pathname.substring(3) || '/');
  }

  render() {
    if (getAvailableLanguages().length < 2) return null;
    const { selectedLanguage } = this.state;

    return (
      <div className={`language-selector-wrapper ${this.props.className}`}>
        <select className="language-selector" onChange={(event) => this.updateLanguage(event.target.value)}>
          {getAvailableLanguages().map((lang, i) => {
            return (
              <option key={i} value={lang} selected={lang === selectedLanguage}>
                {lang === 'en' ? 'Eng' : lang}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

LanguageSelector.propTypes = {
  className: PropTypes.string,
};
