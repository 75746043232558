import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import './header.scss';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import LibrarySelector from '../../../SelectLibrary/components/SelectLibrarySelector/LibrarySelector';
import UserMenu from '../UserMenu/UserMenu';
import Portal from '../../../../common/components/Portal';
import { formatAsDate } from 'utils/dateUtils';
import { i18n, getUrl, goToURL, getCurrentCountry } from 'utils/i18nUtils';
import { setLastSelectedLibrary } from '../../../../utils/localStorageUtils';
import { HIDE_UPCOMING_TITLES_LIBRARY_ID } from '../../../../common/constants';
import { showLoginModal } from 'customer/App/components/Modal/modalActions';
import logo from 'common/assets/logo_black.png';
import logo_fi from 'common/assets/logo_black_fi.png';
import logo_no from 'common/assets/logo_black_no.png';
import logo_mobile from './assets/logo_mobile.png';
import logo_mobile_no from './assets/logo_mobile_no.png';
import logo_mobile_fi from './assets/logo_mobile_fi.png';
import icon_account_circle_black from './assets/icon_account_circle_black.png';
import icon_account_circle_blue from './assets/icon_account_circle_blue.png';
import search_icon_yellow from './assets/icon_search_yellow.svg';
import search_icon_blue from './assets/icon_search_blue.svg';
import vert_dots from './assets/vertical_dots.png';
import x_close_icon from './assets/x_close_icon.png';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showUserMenu: false,
      showLibraryLoanBudgetNotification: true,
      showCustomerLoanBudgetNotification: true,
      showLibrarySelector: false,
    };

    this.onLibraryChange = this.onLibraryChange.bind(this);
  }

  onLibraryChange(option) {
    const libraryId = option.value.split('_')[0];
    setLastSelectedLibrary(libraryId);
    this.setState({ showLibrarySelector: false });
    this.props.goToLibrary(libraryId);
  }

  static isActive(page) {
    return window.location.pathname.indexOf(page) > -1 ? 'active' : '';
  }

  renderNotificationRow(library) {
    const { showLibraryLoanBudgetNotification, showCustomerLoanBudgetNotification } = this.state;
    const libraryLoanBudgetReached = library.get('library_loan_budget_reached');
    const customerLoanBudgetReached = library.get('customer_loan_budget_reached');
    if (
      (!libraryLoanBudgetReached && !customerLoanBudgetReached) ||
      (!showLibraryLoanBudgetNotification && !showCustomerLoanBudgetNotification)
    ) {
      return null; // No budgets are reached or both are closed, return
    }

    const NEW_LOANS_DATE = formatAsDate(new Date(library.get('new_loans_date')));
    const NEW_LOANS_DATE_LIBRARY = formatAsDate(new Date(library.get('new_loans_date_library')));

    let notificationMessage = '';
    let onNotificationClose = undefined;
    if (libraryLoanBudgetReached && showLibraryLoanBudgetNotification) {
      notificationMessage = i18n('LIBRARY_LOAN_BUDGET_REACHED', { NEW_LOANS_DATE_LIBRARY });
      onNotificationClose = () => {
        this.setState({ showLibraryLoanBudgetNotification: false });
      };
    } else if (customerLoanBudgetReached && showCustomerLoanBudgetNotification) {
      notificationMessage = i18n('CUSTOMER_LOAN_BUDGET_REACHED', { NEW_LOANS_DATE });
      onNotificationClose = () => {
        this.setState({ showCustomerLoanBudgetNotification: false });
      };
    }
    if (!notificationMessage) return null;

    return (
      <div className="header-notification">
        <span>{notificationMessage}</span>
        <img className="header-close-icon" alt="X" src={x_close_icon} onClick={onNotificationClose} />
      </div>
    );
  }

  static navigateTo(libraryId, dest) {
    goToURL(`/library/${libraryId}/${dest}`);
  }

  loggedIn(userData) {
    const { config, countryConfig } = this.props;

    return (
      <div>
        <div
          className="header-account blue-text hover-yellow"
          onClick={() => this.setState({ showUserMenu: !this.state.showUserMenu })}
        >
          <img className="header-account-circle" alt="profil" src={icon_account_circle_blue} />
          <div className="hide-sm">{userData.get('first_name')}</div>
        </div>

        {this.state.showUserMenu && (
          <UserMenu
            firstPage={false}
            logout={this.props.userLogout.bind(this)}
            hide={() => this.setState({ showUserMenu: false })}
            config={config}
            countryConfig={countryConfig}
          />
        )}
      </div>
    );
  }

  loggedOut() {
    const { showDotsMenu } = this.state;
    const { countryConfig } = this.props;

    return (
      <div>
        {/* Visible on desktop */}
        <div className="hide-sm">
          <div onClick={this.props.showLoginModal}>
            <img className="header-account-circle" alt="profil" src={icon_account_circle_black} />
            <span className="hover-yellow hide-sm">{i18n('LOG_IN')}</span>
          </div>
        </div>

        {/* Visible on mobile */}
        <div className="show-sm">
          <img
            className="dots"
            alt="..."
            src={vert_dots}
            onClick={() => this.setState({ showDotsMenu: !showDotsMenu })}
          />

          {showDotsMenu && (
            <div>
              <div className="total-overlay" onClick={() => this.setState({ showDotsMenu: false })} />
              <div className="dots-menu">
                <div
                  className="dots-menu-row"
                  onClick={() => {
                    this.setState({ showDotsMenu: false });
                    this.props.showLoginModal();
                  }}
                >
                  <a>{i18n('LOG_IN')}</a>
                </div>
                <div className="dots-menu-row">
                  <a
                    href={getUrl(countryConfig, 'help')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-help"
                  >
                    {i18n('HELP')}
                  </a>
                </div>
                <div className="dots-menu-row">
                  <LanguageSelector />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderTopRow(library, userLibraries, libraries, userData) {
    return (
      <div className="header-top-row">
        <div>
          <img
            onClick={() => goToURL('/')}
            className="header-cineasterna-logo hide-xs"
            alt={i18n('LOGO_ALT_TEXT')}
            src={this.getLogo()}
          />

          <img
            onClick={() => goToURL('/')}
            className="header-cineasterna-logo-mobile show-xs"
            alt={i18n('LOGO_ALT_TEXT')}
            src={this.getMobileLogo()}
          />
        </div>

        <div className="header-library-selector">
          {!this.state.showLibrarySelector && (
            <img
              className="header-library-logo"
              alt={library.get('name')}
              src={library.get('logo_url')}
              onClick={() => this.setState({ showLibrarySelector: true })}
            />
          )}
          {this.state.showLibrarySelector && (
            <LibrarySelector
              userLibraries={userLibraries}
              libraries={libraries}
              selectedLibraryId={library.get('id')}
              onChange={this.onLibraryChange}
              onBlur={() => this.setState({ showLibrarySelector: false })}
              variant="secondary"
              defaultMenuIsOpen
            />
          )}
        </div>

        <div className="header-controls">
          <LanguageSelector className="hide-sm" />

          <div className="header-user-controls align-middle">
            {userData ? this.loggedIn(userData) : this.loggedOut()}
          </div>
        </div>
      </div>
    );
  }

  static renderMidRow(library) {
    const ALL = 'all';
    const SEARCH = 'search';
    const SELECTED = 'selected';
    const UPCOMING = 'nyheter';
    const libraryId = library.get('id');
    const hideUpcoming = HIDE_UPCOMING_TITLES_LIBRARY_ID === libraryId;

    return (
      <div className={`header-mid-row ${hideUpcoming ? 'hide-upcoming' : ''}`}>
        <div className={`header-nav-link ${Header.isActive(SELECTED)}`}>
          <a onClick={() => Header.navigateTo(libraryId, SELECTED)}>{i18n('SELECTED_TITLES')}</a>
        </div>

        <div className={`header-nav-link ${Header.isActive(ALL)}`}>
          <a
            onClick={() => {
              Header.navigateTo(libraryId, ALL);
            }}
          >
            {i18n('ALL_TITLES')}
          </a>
        </div>

        {!hideUpcoming && (
          <div className={`header-nav-link ${Header.isActive(UPCOMING)}`}>
            <a onClick={() => goToURL('/nyheter')}>{i18n('UPCOMING_TITLES')}</a>
          </div>
        )}

        <div className={`header-nav-link search-link ${Header.isActive(SEARCH)}`}>
          <a onClick={() => Header.navigateTo(libraryId, SEARCH)}>
            <img alt="sök" src={Header.isActive(SEARCH) ? search_icon_blue : search_icon_yellow} />
          </a>
        </div>
      </div>
    );
  }

  getLogo() {
    switch (getCurrentCountry()) {
      case 'no':
        return logo_no;
      case 'fi':
        return logo_fi;
      default:
        return logo;
    }
  }

  getMobileLogo() {
    switch (getCurrentCountry()) {
      case 'no':
        return logo_mobile_no;
      case 'fi':
        return logo_mobile_fi;
      default:
        return logo_mobile;
    }
  }

  render() {
    const { library, userLibraries, libraries, userData, hideMidRow } = this.props;

    return (
      <div className="header-container">
        {this.renderNotificationRow(library)}
        <div>
          <Portal container="header-top-row-container">
            {/* header-top-row-container can be found in index.js */}
            {this.renderTopRow(library, userLibraries, libraries, userData)}
          </Portal>

          <div className="grey-bottom-border" />

          <div className="container scrollable">{hideMidRow ? null : Header.renderMidRow(library)}</div>

          <div className="grey-bottom-border" />
        </div>

        <div id="title-filter-container" />
        <div id="search-titles-input-container" />
      </div>
    );
  }
}

Header.propTypes = {
  library: PropTypes.instanceOf(Map),
  userData: PropTypes.instanceOf(Map),
  userLogout: PropTypes.func.isRequired,
  loadLibrary: PropTypes.func.isRequired,
  libraries: PropTypes.object,
  userLibraries: PropTypes.object,
  goToLibrary: PropTypes.func.isRequired,
  hideMidRow: PropTypes.bool,
  config: PropTypes.instanceOf(Map),
  countryConfig: PropTypes.instanceOf(Map),
};

export default connect(null, {
  showLoginModal,
})(Header);
