import { CALL_API } from 'middlewares/apiMiddleware';
import { getCurrentLanguage, getCurrentCountry } from 'utils/i18nUtils';

export const LOADED_LIBRARY = Symbol('LOADED_LIBRARY');
export const LOADED_CONFIGURATION = Symbol('LOADED_CONFIGURATION');
export const LOADED_COUNTRY_CONFIGURATION = Symbol('LOADED_COUNTRY_CONFIGURATION');

export function loadLibrary(libraryId) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/library/library/get_library',
      query: {
        library_id: libraryId,
      },
      successType: LOADED_LIBRARY,
    },
  };
}

export function loadConfiguration() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/configuration/configuration/get_config',
      query: {
        site_lang: getCurrentLanguage(),
      },
      successType: LOADED_CONFIGURATION,
    },
  };
}

export function loadCountryConfiguration() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/configuration/country_configuration/get_country_config',
      query: {
        country_code: getCurrentCountry(),
      },
      successType: LOADED_COUNTRY_CONFIGURATION,
    },
  };
}
