import {CALL_API} from 'middlewares/apiMiddleware';
import {getCurrentCountry} from 'utils/i18nUtils';

export const LOADED_LIBRARY_TITLES = Symbol('LOADED_LIBRARY_TITLES');

export function loadLibraryTitles({
                                      libraryId,
                                      locale,
                                      page = 1,
                                      sort,
                                      genres,
                                      languages,
                                      years,
                                      parental_guidance,
                                      ratings,
                                      title_country_iso = null,
                                      personnel = null
                                  }) {
    // If the ratings range is 0-10, show also titles without a rating
    let apiObject = {
        method: 'get',
        path: '/library/title/get_titles',
        query: {
            library_id: libraryId,
            country_iso: getCurrentCountry(),
            page,
            locale,
            sort,
            genres: `[${genres}]`,
            languages: `[${languages}]`,
            years: `[${years}]`,
            parental_guidance: `[${parental_guidance}]`,
            ratings: `[${ratings}]`,
        },
        successType: LOADED_LIBRARY_TITLES,
    }

    if (title_country_iso != null) {
        apiObject["query"]["title_country_iso"] = title_country_iso
    } else if (personnel != null) {
        apiObject["query"]["personnel"] = personnel
    }

        return {
            [CALL_API]: apiObject,
            isFirstPage: page === 1,
        };
}
