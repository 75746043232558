import React from 'react';

function CardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path d="M 0 0 L 24 0 L 24 24 L 0 24 Z" fill="transparent"></path>
      <path d="M 12 2 C 6.48 2 2 6.48 2 12 C 2 17.52 6.48 22 12 22 C 17.52 22 22 17.52 22 12 C 22 6.48 17.52 2 12 2 Z M 12 5 C 13.66 5 15 6.34 15 8 C 15 9.66 13.66 11 12 11 C 10.34 11 9 9.66 9 8 C 9 6.34 10.34 5 12 5 Z M 12 19.2 C 9.587 19.2 7.334 17.991 6 15.98 C 6.03 13.99 10 12.9 12 12.9 C 13.99 12.9 17.97 13.99 18 15.98 C 16.666 17.991 14.413 19.2 12 19.2 Z"></path>
    </svg>
  );
}

export default CardIcon;
