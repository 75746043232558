import * as SearchTitlesActionType from './searchTitlesActions';
import * as TitleActionType from '../Title/titleActions';
import { favouriteTitleInArray, unfavouriteTitleInArray } from '../Title/titleReducers';
import Immutable from 'immutable';

let defaultState = Immutable.fromJS({
  titles: [],
  anyTitlesLoaded: true,
  count: undefined,
});

export default function searchTitlesReducers(state = defaultState, action) {
  switch (action.type) {
    case SearchTitlesActionType.LOADED_SEARCH_TITLES:
      if (action.searchIsEmpty) {
        return state.merge({ titles: [] });
      }
      const anyTitlesLoaded = !!(action.response && action.response.titles && action.response.titles.length > 0);
      const count = action.response.count;
      if (action.isFirstPage) {
        return state.merge(Object.assign(action.response, { anyTitlesLoaded, count }));
      }
      if (anyTitlesLoaded) {
        let titles = state.get('titles') ? state.get('titles').toJS() : [];
        titles = titles && titles.concat(action.response.titles);
        return state.merge({ titles, anyTitlesLoaded: true, count });
      }

      return state.merge({ anyTitlesLoaded: false });

    case TitleActionType.TITLE_FAVOURITED:
      return state.merge({ titles: favouriteTitleInArray(state.get('titles'), action) });

    case TitleActionType.TITLE_UNFAVOURITED:
      return state.merge({ titles: unfavouriteTitleInArray(state.get('titles'), action) });

    default:
      return state;
  }
}
