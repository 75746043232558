import React from 'react';

function CardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path d="M 0 0 L 24 0 L 24 24 L 0 24 Z" fill="transparent"></path>
      <path d="M 20 2 L 4 2 C 2.89 2 2 2.89 2 4 L 2 15 C 2 16.11 2.89 17 4 17 L 8 17 L 8 22 L 12 20 L 16 22 L 16 17 L 20 17 C 21.11 17 22 16.11 22 15 L 22 4 C 22 2.89 21.11 2 20 2 Z M 20 15 L 4 15 L 4 13 L 20 13 Z M 20 10 L 4 10 L 4 4 L 20 4 Z"></path>
    </svg>
  );
}

export default CardIcon;
