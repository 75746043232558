import * as ActionType from './selectedTitlesActions';
import Immutable from 'immutable';
import * as TitleActionType from '../Title/titleActions';
import { favouriteTitleInArray, unfavouriteTitleInArray } from '../Title/titleReducers';
import { i18nValue } from '../../utils/i18nUtils';

let defaultState = Immutable.fromJS({
  selectedTitles: [],
  newTitles: [],
  themes: [],
  seeAllTitles: [],
  seeAllTitlesHeading: '',
  anyThemesLoaded: false,
});

export default function selectedTitlesReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOADED_THEMES:
      const themes = action.response.themes.filter((theme) => theme.titles.length > 0);
      const anyThemesLoaded = !!(action.response && action.response.themes && action.response.themes.length > 0);

      const firstPageState = {
        themes,
        anyThemesLoaded,
      };

      const additionalPageState = {
        themes: state.get('themes').toJS().concat(themes),
        anyThemesLoaded,
      };

      return state.merge(action.isFirstPage ? firstPageState : additionalPageState);

    case ActionType.LOADED_ALL_SELECTED_TITLES:
      const {
        heading,
        response: { titles },
      } = action;

      return state.merge({ seeAllTitles: titles, seeAllTitlesHeading: heading });

    case ActionType.LOADED_ALL_THEME_TITLES:
      const { theme } = action.response;

      return state.merge({ seeAllTitles: theme.titles, seeAllTitlesHeading: i18nValue(theme) });

    case ActionType.LOADED_SELECTED_TITLES:
      const { selectedTitles, newTitles } = action.response;
      const newSelectedTitlesState = { selectedTitles, newTitles };

      return state.merge(newSelectedTitlesState);

    case ActionType.SET_SEE_ALL_TITLES:
      return state.merge({ seeAllTitles: action.titles, seeAllTitlesHeading: action.heading });

    case TitleActionType.TITLE_FAVOURITED:
      const newFavouritedState = {
        selectedTitles: favouriteTitleInArray(state.get('selectedTitles'), action),
        newTitles: favouriteTitleInArray(state.get('newTitles'), action),
        seeAllTitles: favouriteTitleInArray(state.get('seeAllTitles'), action),
        themes: state
          .get('themes')
          .map((theme) => theme.set('titles', favouriteTitleInArray(theme.get('titles'), action))),
      };
      return state.merge(newFavouritedState);

    case TitleActionType.TITLE_UNFAVOURITED:
      const newUnfavouritedState = {
        selectedTitles: unfavouriteTitleInArray(state.get('selectedTitles'), action),
        newTitles: unfavouriteTitleInArray(state.get('newTitles'), action),
        seeAllTitles: unfavouriteTitleInArray(state.get('seeAllTitles'), action),
        themes: state
          .get('themes')
          .map((theme) => theme.set('titles', unfavouriteTitleInArray(theme.get('titles'), action))),
      };
      return state.merge(newUnfavouritedState);

    default:
      return state;
  }
}
