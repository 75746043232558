import React, { Component } from 'react';
import Popup from 'common/components/Popup/Popup';
import PropTypes from 'prop-types';
import { defaultErrorMsg, defaultErrorTitle } from './errorMessages';

export function triggerErrorPopup(errorStatus) {
  if (errorStatus === null || errorStatus >= 500) {
    return true;
  }

  return false;
}

export default class ErrorPopup extends Component {
  render() {
    return (
      <Popup
        errorPopup={true}
        title={defaultErrorTitle + (this.props.errorStatus ? ': ' + this.props.errorStatus : '')}
        message={this.props.errorMsg ? this.props.errorMsg : defaultErrorMsg}
        hide={this.props.hide.bind(this)}
        showCloseIcon={true}
        isLeftText={true}
        hideFooter={true}
      />
    );
  }
}

ErrorPopup.propTypes = {
  errorStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hide: PropTypes.func.isRequired,
};
