const libraryTheme = {
  356: {
    library: 'Leppävirta',
    font: 'Poppins, sans-serif',
  },
  349: {
    library: 'Kirkes',
    font: 'Arial, sans-serif',
  },
  350: {
    library: 'Vaara',
    font: 'Segoe UI, sans-serif',
  },
  377: {
    library: 'Ratamo',
    font: 'Roboto, sans-serif',
  },
  376: {
    library: 'Suonenjoki',
    font: 'Open Sans, sans-serif',
  },
  373: {
    library: 'Sata',
    font: 'Raleway, sans-serif',
  },
  370: {
    library: 'Heili',
    font: 'Roboto, sans-serif',
  },
  352: {
    library: 'Blanka',
    font: 'Open Sans, sans-serif',
  },
  371: {
    library: 'Lastu',
    font: 'Open Sans, sans-serif',
  },
  353: {
    library: 'Rutakko',
    font: 'Open Sans, Arial, sans-serif',
  },
  351: {
    library: 'Eepos',
    font: 'Arial, sans-serif',
  },
  378: {
    library: 'Piki',
    font: 'Work Sans, sans-serif',
  },
  374: {
    library: 'Keski',
    font: 'Roboto, sans-serif',
  },
  367: {
    library: 'Anders',
    font: 'Segoe UI, sans-serif',
  },
  347: {
    library: 'Lumme',
    font: 'Segoe UI, Roboto',
  },
  345: {
    library: 'Louna',
    font: 'Segoe UI, Arial, Helvetica',
  },
  348: {
    library: 'Vaski',
    font: 'Helvetica, Arial',
  },
};

const stageLibraryTheme = {
  8: {
    library: 'Testbibliotek',
    font: 'Segoe UI, Arial',
    color: '398a9e',
  },
};

export default process.env.NODE_ENV === 'production' ? libraryTheme : stageLibraryTheme;
