import React, { Component } from 'react';
import { i18n } from 'utils/i18nUtils';
import { IS_COMPATIBLE, isChromeForIOS } from 'common/constants';
import './CompatibilityWarning.scss';

export default class CompatibilityWarning extends Component {
  suggestedBrowser() {
    return isChromeForIOS ? 'Safari' : 'Google Chrome';
  }
  render() {
    if (IS_COMPATIBLE) {
      return null;
    }

    return (
      <div className="non-supported-browser-notice">
        {i18n('BROWSER_NOT_SUPPORTED', { SUGGESTED_BROWSER: this.suggestedBrowser() })}
      </div>
    );
  }
}
