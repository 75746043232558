import React, { Component } from 'react';
import PropTypes from 'prop-types';
import close_icon from '../../assets/close.png';
import './Popup.scss';

export default class Popup extends Component {
  render() {
    return (
      <div>
        <div
          className={'total-overlay' + (this.props.errorPopup ? ' darker' : ' dark')}
          onClick={this.props.hide.bind(this)}
        />

        <div className={'popup-box block-shadow' + (this.props.errorPopup ? ' error-popup' : '')}>
          {this.props.showCloseIcon ? (
            <div>
              <div className="popup-close">
                <img src={close_icon} onClick={this.props.hide.bind(this)} className="close-icon" alt="" />
              </div>
              <div className="clear" />
            </div>
          ) : null}

          {this.props.title ? <div className="popup-title">{this.props.title}</div> : null}

          <div className={'popup-box-content' + (this.props.isLeftText ? ' left-text' : '')}>
            {typeof this.props.message === 'string'
              ? this.props.message
              : this.props.message.map((messagePart) => {
                  switch (messagePart.type) {
                    case 'button':
                      return (
                        <div className="popup-centered-button">
                          <button className={messagePart.className || 'button'} onClick={messagePart.onClick}>
                            {messagePart.content}
                          </button>
                        </div>
                      );
                    case 'text':
                    default:
                      const hasIndicator = typeof messagePart.numberIndicator === 'number';
                      return (
                        <div>
                          {hasIndicator ? (
                            <span className="popup-message-indicator">{messagePart.numberIndicator}</span>
                          ) : null}
                          <span className={hasIndicator ? 'popup-next-to-indicator' : undefined}>
                            {messagePart.content}
                          </span>
                        </div>
                      );
                  }
                })}
          </div>

          {!this.props.hideFooter && (
            <div className="popup-footer">
              {this.props.buttons ? (
                this.props.buttons.map((buttonInfo) => {
                  return (
                    <div className={buttonInfo.isCentered ? 'popup-centered-button' : undefined}>
                      <button className={buttonInfo.className || 'button'} onClick={buttonInfo.onClick}>
                        {buttonInfo.text}
                      </button>
                    </div>
                  );
                })
              ) : (
                <button className="button" onClick={this.props.hide.bind(this)}>
                  OK
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

Popup.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  hide: PropTypes.func.isRequired,
  buttons: PropTypes.array, // if not specified, default OK button that just hides the popup
  showCloseIcon: PropTypes.bool,
  hideFooter: PropTypes.bool,
  isLeftText: PropTypes.bool,
  errorPopup: PropTypes.bool,
};
