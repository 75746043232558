import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImmutableLoadingBar as ReactReduxLoadingBar } from 'react-redux-loading-bar';
import './LoadingBar.scss';

export default class LoadingBar extends Component {
  render() {
    return <ReactReduxLoadingBar className={'loading-bar' + (this.props.isLight ? ' loading-bar-light' : '')} />;
  }
}

LoadingBar.propTypes = {
  isLight: PropTypes.bool, // not required as if undefined it's considered false
};
