import * as ActionType from './libSettingsActions';
import Immutable from 'immutable';

let defaultState = Immutable.fromJS({
  changedPasswordResult: {},
  sendEmailResult: undefined,
});

export default function libSettingsReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.CHANGED_PASSWORD:
      return state.merge({ changedPasswordResult: action.response });
    case ActionType.SENT_EMAIL:
      return state.merge({ sendEmailResult: action.response });
    default:
      return state;
  }
}
