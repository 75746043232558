import { CALL_API } from 'middlewares/apiMiddleware';

export const CHANGED_PASSWORD = Symbol('CHANGED_PASSWORD');
export const SENT_EMAIL = Symbol('SENT_EMAIL');

export function changePassword(password, newPassword) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/librarian/librarian/change_password',
      body: {
        password: password,
        new_password: newPassword,
      },
      successType: CHANGED_PASSWORD,
    },
  };
}

export function sendEmail(subject, contactEmail, message) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/i18n/help/send_email',
      body: {
        subject,
        contact_email: contactEmail,
        message,
      },
      successType: SENT_EMAIL,
    },
  };
}
