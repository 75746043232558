import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

class Button extends Component {
  render() {
    const { className, type, onClick, size, modifier, variant, children, disabled } = this.props;

    return (
      <button
        className={`button-v2 ${className} ${size} ${modifier} ${variant}`}
        type={type}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  modifier: PropTypes.oneOf(['action', 'success', 'warning', 'danger']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'link']),
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  type: 'button',
  onClick: null,
  size: 'sm',
  modifier: null,
  variant: 'primary',
  disabled: false,
};

export default Button;
