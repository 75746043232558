import { CALL_API } from 'middlewares/apiMiddleware';

export const LOAD_EXTRA_STATISTIC_INFO = Symbol('LOAD_EXTRA_STATISTIC_INFO');

export function loadExtraStatisticInfo() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/librarian/librarian/get_general_info',
      query: {},
      successType: LOAD_EXTRA_STATISTIC_INFO,
    },
  };
}
