import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { i18n } from 'utils/i18nUtils';
import { resetNetworkStatus } from 'middlewares/networkActions';
import { addLibraryCard, ADDED_INVENTORY } from '../../userSettingsActions';
import { getLastSelectedLibrary } from 'utils/localStorageUtils';
import LibrarySelector from 'customer/SelectLibrary/components/SelectLibrarySelector/LibrarySelector';
import Loader from '../../../../common/components/Loader/Loader';
import Button from 'common/components/Button/Button';
import Input from 'common/components/Input/Input';
import CardIcon from 'common/assets/icons/CardIcon';
import LockIcon from 'common/assets/icons/LockIcon';
import 'customer/Auth/components/Login/Login.scss';

class AddLibraryCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      libraryId: getLastSelectedLibrary(),
      cardNumber: null,
      pinCode: null,
    };

    this.handleOnChangeCardNumber = this.handleOnChangeCardNumber.bind(this);
    this.handleOnChangePinCode = this.handleOnChangePinCode.bind(this);
    this.onLibraryChange = this.onLibraryChange.bind(this);
    this.addLibraryCard = this.addLibraryCard.bind(this);
  }

  onLibraryChange(option) {
    const libraryId = option.value.split('_')[0];
    this.setState({ libraryId: libraryId });
  }

  handleOnChangeCardNumber(e) {
    this.setState({ cardNumber: e.target.value });
  }

  handleOnChangePinCode(e) {
    this.setState({ pinCode: e.target.value });
  }

  addLibraryCard(e) {
    e.preventDefault();
    this.props.addLibraryCard(this.state.libraryId, this.state.cardNumber, this.state.pinCode);
  }

  render() {
    const { userLibraries, libraries } = this.props;

    return (
      <form className="login-form" onSubmit={this.addLibraryCard} noValidate>
        <div className="login-content">
          <p className="login-info">{i18n('ADD_LIBRARY_CARD_BOX_TEXT')}</p>
          <Input
            className="login-input"
            type="text"
            placeholder={i18n('FILL_CARD_NUMBER')}
            icon={CardIcon}
            value={this.state.cardNumber}
            onChange={this.handleOnChangeCardNumber}
          />
          <Input
            className="login-input"
            type="password"
            placeholder={i18n('FILL_PIN_CODE')}
            icon={LockIcon}
            value={this.state.pinCode}
            onChange={this.handleOnChangePinCode}
            errorMessage={this.props.addLibraryCardRequestStatus.error ? i18n('WRONG_CARD_NUMBER_OR_PIN_CODE') : null}
          />
          {libraries ? (
            <LibrarySelector
              libraries={libraries}
              userLibraries={userLibraries}
              selectedLibraryId={this.state.libraryId}
              onChange={this.onLibraryChange}
              onBlur={() => this.setState({ showLibrarySelector: false })}
            />
          ) : (
            <Loader inverted={true} />
          )}
        </div>
        <Button className="submit-button" type="submit" disabled={this.props.addLibraryCardRequestStatus.pending}>
          {i18n('ADD')}
        </Button>
      </form>
    );
  }
}

AddLibraryCard.defaultProps = {
  addLibraryCardRequestStatus: {},
};

AddLibraryCard.propTypes = {
  userLibraries: PropTypes.object.isRequired,
  libraries: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    addLibraryCardRequestStatus: state.get('networkReducers')[ADDED_INVENTORY],
    libraries: state.get('libraryReducers').get('libraries'),
    userLibraries: state.get('libraryReducers').get('userLibraries'),
  };
}

export default connect(mapStateToProps, {
  addLibraryCard,
  resetNetworkStatus,
})(AddLibraryCard);
