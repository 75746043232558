import { LOC_LAST_WATCH_TIMES } from 'common/constants';
import { LOC_LAST_SELECTED_LIBRARY } from '../common/constants';
import { getCurrentLanguage } from './i18nUtils';

export const getLastSelectedLibrary = () => {
  if (window.localStorage) {
    const libraryId = localStorage.getItem(LOC_LAST_SELECTED_LIBRARY + '__' + getCurrentLanguage());
    if (libraryId) {
      return parseInt(libraryId);
    }
  }
  return -1;
};

export const setLastSelectedLibrary = (libraryId) => {
  if (window.localStorage) {
    localStorage.setItem(LOC_LAST_SELECTED_LIBRARY + '__' + getCurrentLanguage(), libraryId);
  }
};

const getLastWatchTimes = () => {
  try {
    return typeof localStorage !== 'undefined' && localStorage.getItem(LOC_LAST_WATCH_TIMES)
      ? JSON.parse(localStorage.getItem(LOC_LAST_WATCH_TIMES))
      : null;
  } catch (e) {
    return null;
  }
};

export const updateLastWatchTime = (titleId, time) => {
  const lastWatchTimes = getLastWatchTimes() || {};
  if (titleId) {
    lastWatchTimes[titleId] = time;
    try {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(LOC_LAST_WATCH_TIMES, JSON.stringify(lastWatchTimes));
      }
    } catch (e) {}
  }
};

export const getLastWatchTime = (titleId, isGetAndReset = false, delayBeforeReset = 0) => {
  const lastWatchTimes = getLastWatchTimes();
  const time = lastWatchTimes && titleId && lastWatchTimes[titleId] ? Number(lastWatchTimes[titleId]) : 0;
  if (time && isGetAndReset) {
    if (delayBeforeReset) {
      setTimeout(() => updateLastWatchTime(titleId, 0), delayBeforeReset);
    } else {
      updateLastWatchTime(titleId, 0);
    }
  }
  return time >= 10 ? time : 0;
};
