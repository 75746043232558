export default function networkReducers(state = {}, action) {
  switch (action.type) {
    case 'NETWORK_PENDING':
      return { ...state, [action.origin]: { pending: true, success: false, error: false, errorCause: null } };
    case 'NETWORK_SUCCESS':
      return { ...state, [action.origin]: { pending: false, success: true, error: false, errorCause: null } };
    case 'NETWORK_ERROR':
      return { ...state, [action.origin]: { pending: false, success: false, error: true, errorCause: action.err } };
    case 'NETWORK_RESET':
      return { ...state, [action.origin]: { pending: false, success: false, error: false, errorCause: null } };

    default:
      return state;
  }
}
