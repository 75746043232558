import React from 'react';

function CloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        d="M 19.5 17.117 L 14.303 11.987 L 19.428 6.811 L 17.117 4.5 L 11.985 9.699 L 6.791 4.572 L 4.5 6.863 L 9.701 12.013 L 4.572 17.209 L 6.863 19.5 L 12.011 14.301 L 17.189 19.428 Z"
        fill="rgb(0,0,0)"
      ></path>
    </svg>
  );
}

export default CloseIcon;
