import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { i18n } from '../../../../utils/i18nUtils';

export default class LibrarySelector extends Component {
  getLibrarySelectorOptions() {
    const { userLibraries, libraries } = this.props;

    const optionGroups = [];

    if (userLibraries && userLibraries.size > 0) {
      optionGroups.push({
        label: i18n('YOUR_LIBRARIES'),
        options: userLibraries.toArray().map((lib) => ({ value: lib.get('id').toString(), label: lib.get('name') })),
      });
    }

    const allLibraries = [];
    libraries.toArray().forEach((library) => {
      allLibraries.push({ value: library.get('id').toString(), label: library.get('name') });
      if (library.get('extra_display_names')) {
        library
          .get('extra_display_names')
          .split(',')
          .sort((a, b) => a.trim().localeCompare(b.trim()))
          .map((displayName, index) =>
            allLibraries.push({
              value: library.get('id') + '_' + index,
              label: `\xa0\xa0\xa0\xa0 ${displayName.trim()}`,
            })
          );
      }
    });

    optionGroups.push({
      label: i18n('ALL_LIBRARIES'),
      options: allLibraries,
    });

    return optionGroups;
  }

  getLibrarySelectorStyles() {
    let styles = {
      container: (provided, state) => ({
        ...provided,
        width: '100%',
        margin: 'auto',
        textAlign: 'left',
      }),
      input: (provided, state) => ({
        ...provided,
        paddingTop: 11,
        paddingBottom: 11,
      }),
      menu: (provided, state) => ({
        ...provided,
        width: 300,
        margin: 'auto',
      }),
    };

    if (this.props.variant === 'secondary') {
      styles.control = (provided, state) => ({
        ...provided,
        border: 'none',
        boxShadow: 'none',
      });
      styles.menu = (provided, state) => ({
        ...provided,
        width: 300,
        margin: 'auto',
        transform: 'translateX(-20%)',
      });
    }

    return styles;
  }

  render() {
    const options = this.getLibrarySelectorOptions();
    const selectedOption =
      this.props.selectedLibraryId &&
      options.flatMap((e) => e.options).find(({ value }) => value === this.props.selectedLibraryId.toString());

    return (
      <Select
        autoFocus={this.props.defaultMenuIsOpen}
        styles={this.getLibrarySelectorStyles()}
        defaultMenuIsOpen={this.props.defaultMenuIsOpen}
        options={options}
        placeholder={i18n('CHOOSE_LIBRARY')}
        value={selectedOption}
        onChange={this.props.onChange}
        onBlur={this.props.onBlur}
        onMenuClose={this.props.onBlur}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#d8c47a',
            primary: '#cfb644',
          },
        })}
      />
    );
  }
}

LibrarySelector.propTypes = {
  userLibraries: PropTypes.object.isRequired,
  libraries: PropTypes.object.isRequired,
  selectedLibraryId: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  defaultMenuIsOpen: PropTypes.bool,
};

LibrarySelector.defaultProps = {
  variant: 'primary',
  defaultMenuIsOpen: false,
};
