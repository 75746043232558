import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Banner.scss';

export class Banner extends Component {
  render() {
    return <div className={'front-page-banner-wrapper front-page-banner-' + this.props.status}>{this.props.text}</div>;
  }
}

Banner.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Banner;
