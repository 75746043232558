import cookie from 'react-cookie';
import { PORTAL_NAME } from 'common/constants';

// Max age 14 days minus 3 hours due to 14 days session cache timeout on back-end.
const COOKIE_MAX_AGE = 1198800;

export const setSessionIdCookie = (sessionId) => {
  if (sessionId) {
    cookie.save(PORTAL_NAME + '_sessionid', sessionId, { path: '/', maxAge: COOKIE_MAX_AGE });
  } else {
    cookie.remove(PORTAL_NAME + '_sessionid', { path: '/' });
  }
};

export const setCsrfTokenCookie = (csrfToken, resetSessionIdMaxAge = true) => {
  if (csrfToken) {
    cookie.save(PORTAL_NAME + '_csrftoken', csrfToken, { path: '/', maxAge: COOKIE_MAX_AGE });

    // Also reset session ID max age as the session cache timeout on back-end has been renewed for all session data.
    if (resetSessionIdMaxAge && cookie.load(PORTAL_NAME + '_sessionid')) {
      cookie.save(PORTAL_NAME + '_sessionid', cookie.load(PORTAL_NAME + '_sessionid'), {
        path: '/',
        maxAge: COOKIE_MAX_AGE,
      });
    }
  } else {
    cookie.remove(PORTAL_NAME + '_csrftoken', { path: '/' });
  }
};
