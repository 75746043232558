import * as AllTitlesActionType from './allTitlesActions';
import * as TitleActionType from '../Title/titleActions';
import { favouriteTitleInArray, unfavouriteTitleInArray } from '../Title/titleReducers';
import Immutable from 'immutable';

let defaultState = Immutable.fromJS({
  titles: [],
  anyTitlesLoaded: true,
});

export default function allTitlesReducers(state = defaultState, action) {
  let titles;
  switch (action.type) {
    case AllTitlesActionType.LOADED_LIBRARY_TITLES:
      const anyTitlesLoaded = !!(action.response && action.response.titles && action.response.titles.length > 0);
      if (action.isFirstPage) {
        return state.merge(Object.assign(action.response, { anyTitlesLoaded }));
      }
      if (anyTitlesLoaded) {
        titles = state.get('titles') ? state.get('titles').toJS() : [];
        titles = titles && titles.concat(action.response.titles);
        return state.merge({ titles, anyTitlesLoaded: true });
      }

      return state.merge({ anyTitlesLoaded: false });

    case TitleActionType.TITLE_FAVOURITED:
      return state.merge({ titles: favouriteTitleInArray(state.get('titles'), action) });

    case TitleActionType.TITLE_UNFAVOURITED:
      return state.merge({ titles: unfavouriteTitleInArray(state.get('titles'), action) });

    default:
      return state;
  }
}
