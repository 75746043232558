import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { browserHistory } from 'react-router';
import { i18nUrl, goToURL } from 'utils/i18nUtils';
import { ellipsize } from 'utils/stringUtils';
import './LibHeader.scss';
import logo from 'common/assets/logo_black.png';
import LanguageSelector from 'customer/App/components/LanguageSelector/LanguageSelector';
import { i18n, i18nLogo } from 'utils/i18nUtils';

export default class LibHeader extends Component {
  logout() {
    this.props.logout().then(() => {
      if (this.props.attemptedLogoutResult && this.props.attemptedLogoutResult.get('success')) {
        browserHistory.push(i18nUrl('/lib/login'));
      }
    });
  }

  render() {
    return (
      <>
      <div className="lib-header block-shadow">
        <img
          alt="Cineasterna"
          className="lib-header-logo"
          src={i18nLogo("black")}
          onClick={() => goToURL('/lib/' + (this.props.librarian ? '' : 'login'))}
        />

          {this.props.librarian && (
            <div className="librarian-menu">
              <a onClick={() => goToURL('/lib/catalogue')}>{i18n('HEADER_MY_TITLES')}</a>
              <a onClick={() => goToURL('/lib/')}>{i18n('HEADER_STATISTICS')}</a>
              <a
                href={i18nUrl('/library/' + this.props.librarian.get('library_id') + '/all')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n('HEADER_CATALOG')}
              </a>
              <a onClick={() => goToURL('/lib/settings')}>{i18n('HEADER_CHANGE_BUDGET')}</a>
              <a onClick={this.logout.bind(this)}>
                {i18n('HEADER_LOGOUT') + ' '}
              <span className="librarian-user">({ellipsize(this.props.librarian.get('username'), 20)})</span>
              </a>
              <div className="clear" />
            </div>
          )}
          <div className="hide-sm lib-language">
            <LanguageSelector />
          </div>
        </div>
      </>
    );
  }
}

LibHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  attemptedLogoutResult: PropTypes.instanceOf(Map).isRequired,
  librarian: PropTypes.instanceOf(Map), // not required if user not logged in
};
