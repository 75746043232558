import { useEffect } from 'react';
import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

export default function CookieConsent() {
  const detectLanguage = () => {
    const pattern = /\/(.+?)\/(.+)?/;
    const m = pattern.exec(window.location.pathname);

    if (m.length < 2) {
      console.warn('Unable to determine page language, defaulting to English');
      return 'en';
    }

    console.log(m[1]);
    return m[1];
  };

  useEffect(() => {
    if (!document.getElementById('cc--main')) {
      window.CookieConsentApi = window.initCookieConsent();
      window.CookieConsentApi.run({
        current_lang: detectLanguage(),
        autoclear_cookies: true, // default: false
        page_scripts: false, // default: false
        gui_options: {
          consent_modal: {
            layout: 'cloud',
            position: 'bottom center',
          },
        },

        // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        // delay: 0,                               // default: 0
        // auto_language: null,                      // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: false,                   // default: false
        // hide_from_bots: false,                  // default: false
        // remove_cookie_tables: false             // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        onFirstAction: function (user_preferences, cookie) {
          // callback triggered only once on the first accept/reject action

          if (user_preferences.accepted_categories.includes('analytics')) {
            window.gtag('consent', 'update', {
              analytics_storage: 'granted',
            }); // Defined in index.html
          }
        },

        onAccept: function (cookie) {
          // callback triggered on the first accept/reject action, and after each page load
        },

        onChange: function (cookie, changed_categories) {
          // callback triggered when user changes preferences after consent has already been given
        },

        languages: {
          en: {
            consent_modal: {
              title: 'We use cookies!',
              description:
                'Hi, this website uses cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>.',
              primary_btn: {
                text: 'Accept all',
                role: 'accept_all', // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Reject all',
                role: 'accept_necessary', // 'settings' or 'accept_necessary'
              },
            },
            settings_modal: {
              title: 'Cookie preferences',
              save_settings_btn: 'Save settings',
              accept_all_btn: 'Accept all',
              reject_all_btn: 'Reject all',
              close_btn_label: 'Close',
              cookie_table_headers: [
                { col1: 'Name' },
                { col2: 'Domain' },
                { col3: 'Expiration' },
                { col4: 'Description' },
              ],
              blocks: [
                {
                  title: 'Cookie usage 📢',
                  description:
                    'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a target="_blank" href="https://d3k9jeyu3pq1ex.cloudfront.net/static/frontend/personuppgiftspolicy.pdf" class="cc-link">privacy policy</a>.',
                },
                {
                  title: 'Strictly necessary cookies',
                  description:
                    'These cookies are essential for the proper functioning of this website. Without these cookies, the website would not work properly.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                  },
                },
                {
                  title: 'Performance and Analytics cookies',
                  description:
                    'These cookies allow us to collect information that can aid us in improving the user experience on the site',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: false,
                    readonly: false,
                  },
                },
              ],
            },
          },
          sv: {
            consent_modal: {
              title: 'Vi använder cookies',
              description:
                'Hej, denna webbplats använder cookies för att säkerställa att den fungerar korrekt och spårningscookies för att förstå hur du interagerar med den. Det senare kommer att fastställas först efter samtycke. <button type="button" data-cc="c-settings" class="cc-link">Låt mig välja</button>.',
              primary_btn: {
                text: 'Acceptera alla',
                role: 'accept_all', // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Avvisa alla',
                role: 'accept_necessary', // 'settings' or 'accept_necessary'
              },
            },
            settings_modal: {
              title: 'Inställningar för cookies',
              save_settings_btn: 'Spara Inställningar',
              accept_all_btn: 'Acceptera Alla',
              reject_all_btn: 'Avvisa Alla',
              close_btn_label: 'Stäng',
              cookie_table_headers: [{ col1: 'Namn' }, { col2: 'Domän' }, { col3: 'Utgång' }, { col4: 'Beskrivning' }],
              blocks: [
                {
                  title: 'Cookieanvändning 📢',
                  description:
                    'Vi använder cookies för att säkerställa de grundläggande funktionerna på webbplatsen och för att förbättra din onlineupplevelse. Du kan för varje kategori välja att delta eller ej när du vill. För mer information om cookies och annan känslig information, läs hela <a target="_blank" href="https://d3k9jeyu3pq1ex.cloudfront.net/static/frontend/personuppgiftspolicy.pdf" class="cc-link">personuppgiftspolicyn</a>.',
                },
                {
                  title: 'Endast nödvändiga cookies',
                  description:
                    'Dessa cookies är nödvändiga för att denna webbplats ska fungera korrekt. Utan dessa cookies skulle webbplatsen inte fungera.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                  },
                },
                {
                  title: 'Prestanda- och Analytics-cookies',
                  description:
                    'Dessa cookies tillåter oss att samla in information som kan hjälpa oss att förbättra användarupplevelsen på webbplatsen.',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: false,
                    readonly: false,
                  },
                },
              ],
            },
          },
          no: {
            consent_modal: {
              title: 'Vi bruker informasjonskapsler',
              description:
                'Hei, nettsted bruker informasjonskapsler for å bruke denne som fungerer korrekt og sporingsinformasjonskapsler for å forstå hvordan du interagerer med den. <button type="button" data-cc="c-settings" class="cc-link">Låt meg velge.</button>.',
              primary_btn: {
                text: 'Acceptera alla',
                role: 'accept_all', // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Avvisa alla',
                role: 'accept_necessary', // 'settings' or 'accept_necessary'
              },
            },
            settings_modal: {
              title: 'Cookie-foretrekker',
              save_settings_btn: 'Spare',
              accept_all_btn: 'Acceptera Alla',
              reject_all_btn: 'Avvisa Alla',
              close_btn_label: 'Stänga',
              cookie_table_headers: [{ col1: 'Navn' }, { col2: 'Domän' }, { col3: 'Utgang' }, { col4: 'Beskrivelse' }],
              blocks: [
                {
                  title: 'Bruk av informasjonskapsler 📢',
                  description:
                    'Vi bruker informasjonskapsler for å bruke grunnleggende funksjoner på nettstedet og for å forbedre din onlineopplevelse. Du kan for hver kategori velge å delta eller ikke når du vil. For mer informasjon om informasjonskapsler og annen følsom informasjon, er <a target="_blank" href="https://d3k9jeyu3pq1ex.cloudfront.net/static/frontend/personuppgiftspolicy.pdf" class="cc-link">hele personopplysningspolicyen.</a>.',
                },
                {
                  title: 'Kun nødvendige informasjonskapsler',
                  description:
                    'Disse informasjonskapslene er nødvendige for at denne nettsiden skal fungere korrekt. Uten disse cookies, vil ikke nettstedet fungere.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                  },
                },
                {
                  title: 'Prestanda- og Analytics-informasjonskapsler',
                  description:
                    'Disse informasjonskapslene tillater oss å samle informasjon som kan hjelpe oss å forbedre brukeropplevelsen på nettstedet.',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: false,
                    readonly: false,
                  },
                },
              ],
            },
          },
        },
      });
    }
  }, []);

  return null;
}
