/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import appReducers from 'customer/App/appReducers';
import networkReducers from 'middlewares/networkReducers';
import libraryReducers from 'customer/SelectLibrary/selectLibraryReducers';
import authReducers from 'customer/Auth/authReducers';
import userProfileReducers from 'customer/UserProfile/userProfileReducers';
import userSettingsReducers from 'customer/UserSettings/userSettingsReducers';
import titleReducers from 'customer/Title/titleReducers';
import libLoginReducers from 'librarian/LibLogin/libLoginReducers';
import libCatalogueReducers from 'librarian/LibCatalogue/libCatalogueReducers';
import libSettingsReducers from 'librarian/LibSettings/libSettingsReducers';
import libStatisticsReducers from 'librarian/LibStatistics/libStatisticsReducers';
import allTitlesReducers from './customer/AllTitles/allTitlesReducers';
import selectedTitlesReducers from './customer/SelectedTitles/selectedTitlesReducers';
import searchTitlesReducers from './customer/SearchTitles/searchTitlesReducers';
import modalReducers from 'customer/App/components/Modal/modalReducers';
import errorReducer from 'common/err/errorReducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  locationBeforeTransitions: null,
  previousPathname: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const previousPathname = state?.get('locationBeforeTransitions')?.get('pathname');
      return state.merge({
        previousPathname,
        locationBeforeTransitions: action.payload,
      });
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    route: routeReducer,
    appReducers: appReducers,
    networkReducers: networkReducers,
    allTitlesReducers: allTitlesReducers,
    selectedTitlesReducers: selectedTitlesReducers,
    searchTitlesReducers: searchTitlesReducers,
    libraryReducers: libraryReducers,
    authReducers: authReducers,
    userProfileReducers: userProfileReducers,
    userSettingsReducers: userSettingsReducers,
    titleReducers: titleReducers,
    libLoginReducers: libLoginReducers,
    libCatalogueReducers: libCatalogueReducers,
    libSettingsReducers: libSettingsReducers,
    libStatisticsReducers: libStatisticsReducers,
    loadingBar: loadingBarReducer,
    modalReducers: modalReducers,
    errorReducer: errorReducer,
    ...asyncReducers,
  });
}
