import * as ActionType from './libCatalogueActions';
import Immutable from 'immutable';

let defaultState = Immutable.fromJS({
  librarian_titles: {},
});

export default function libCatalogueReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOADED_LIBRARIAN_TITLES:
      return state.merge(action.response);

    default:
      return state;
  }
}
