import React from 'react';

export const ellipsize = (text, maxCharNumber) => {
  if (typeof text === 'string' && text.length > maxCharNumber) {
    return text.substring(0, maxCharNumber - 2).trim() + '…';
  } else {
    return text;
  }
};

export const renderTextIntoParagraphs = (text) => {
  if (typeof text === 'string') {
    const formatted = text.split('\n').map(function (line, index) {
      return <p key={index}>{line}</p>;
    });
    return <div>{formatted}</div>;
  }
  return false;
};

// Group Name [S#E#]: some name --> some name (some name could be empty which is okay)
export const cutTitleEpisodeName = (name) => {
  return name ? name.replace(/^.* \[(S[0-9]+)?E[0-9]+\](: )?/, '') : name;
};

export const lightenHexColor = (color, percent) => {
  // Takes a hex color string and lightens the color by the given percent with some bit shifting magic
  let num = parseInt(color, 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt;

  return (
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1); // eslint-disable-line
};
