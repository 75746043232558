import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Input.scss';

class Input extends Component {
  renderIcon() {
    const Icon = this.props.icon;

    if (!Icon) {
      return null;
    }

    return (
      <div className="icon">
        <Icon />
      </div>
    );
  }

  render() {
    const {
      inputRef,
      className,
      type,
      placeholder,
      icon,
      value,
      onChange,
      readonly,
      disabled,
      helpMessage,
      errorMessage,
      theme,
      ...rest
    } = this.props;

    return (
      <div className={`input-container ${className} ${theme}`}>
        <div className={`input-block ${icon ? 'has_icon ' : 'no_icon '}`}>
          {this.renderIcon()}
          <input
            ref={inputRef}
            type={type}
            placeholder={placeholder}
            value={value || undefined}
            onChange={onChange}
            readOnly={readonly}
            autoComplete="off"
            disabled={disabled}
            {...rest}
          />
        </div>
        {helpMessage && !errorMessage ? <small className="input-message">{helpMessage}</small> : null}
        {errorMessage ? <small className="error input-message">{errorMessage}</small> : null}
      </div>
    );
  }
}

Input.propTypes = {
  inputRef: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  helpMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
};

Input.defaultProps = {
  inputRef: null,
  className: '',
  type: 'text',
  placeholder: null,
  icon: null,
  value: undefined,
  readonly: false,
  disabled: false,
  helpMessage: null,
  errorMessage: null,
  theme: 'dark',
};

export default React.forwardRef((props, ref) => <Input inputRef={ref} {...props} />);
