import { CALL_API } from 'middlewares/apiMiddleware';
import { fetchUserData } from 'customer/Auth/authActions';

export const LOADED_TITLE = Symbol('LOADED_TITLE');
export const LOADED_SIMILAR_TITLES = Symbol('LOADED_SIMILAR_TITLES');
export const LOADED_GROUP_TITLES = Symbol('LOADED_GROUP_TITLES');
export const TITLE_LOANED = Symbol('TITLE_LOANED');
export const TITLE_LOANED_ERROR = Symbol('TITLE_LOANED_ERROR');
export const CLEARED_LOAN_INFORMATION = Symbol('CLEARED_LOAN_INFORMATION');
export const TITLE_FAVOURITED = Symbol('TITLE_FAVOURITED');
export const TITLE_UNFAVOURITED = Symbol('TITLE_UNFAVOURITED');
export const LOADED_CURRENT_LOANS_LEFT = Symbol('LOADED_CURRENT_LOANS_LEFT');

export function loadTitle(titleId) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/library/title/get_title',
      query: {
        title_id: titleId,
        with_subtitle_languages: 1,
      },
      successType: LOADED_TITLE,
    },
  };
}

// TODO: fix bug with libraryId sometimes 'undefined'
export function loadSimilarTitles(libraryId, titleId) {
    return {
        [CALL_API]: {
            method: 'get',
            path: '/library/title/get_title_suggestions',
            query: {
                library_id: libraryId,
                title_id: titleId,
            },
            successType: LOADED_SIMILAR_TITLES,
        }
    }
}

export function loadGroupTitles(titleGroupId) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/library/title/get_group_titles',
      query: {
        group_id: titleGroupId,
      },
      successType: LOADED_GROUP_TITLES,
    },
  };
}

export function loanTitle(cardNumber, pinCode, libraryId, titleId) {
  return (dispatch) => {
    return dispatch({
      [CALL_API]: {
        method: 'post',
        path: '/customer/customer/loan',
        body: {
          card_number: typeof cardNumber === 'string' ? cardNumber.trim() : cardNumber,
          pin_code: typeof pinCode === 'string' ? pinCode.trim() : pinCode,
          library_id: libraryId,
          title_id: titleId,
        },
        successType: TITLE_LOANED,
        errorType: TITLE_LOANED_ERROR,
        errorExempt: true,
      },
    })
      .then(() => dispatch(fetchUserData()))
      .catch(() => {});
  };
}

export function unlockEhubTitle(access_token) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/customer/customer/ehub_loan',
      body: { access_token },
      successType: TITLE_LOANED,
    },
  };
}

export function clearLoanInformation() {
  return {
    type: CLEARED_LOAN_INFORMATION,
  };
}

export function favouriteTitle(titleId, libraryId) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/user/favourite/add_favourite',
      body: { title_id: titleId, library_id: libraryId },
      successType: TITLE_FAVOURITED,
    },
  };
}

export function unfavouriteTitle(titleId) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/user/favourite/remove_favourite',
      body: { title_id: titleId },
      successType: TITLE_UNFAVOURITED,
    },
  };
}

export function getCurrentLoansLeft(cardNumber, pinCode, libraryId) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/customer/customer/get_current_month_loans_left',
      query: {
        card_number: cardNumber,
        pin_code: pinCode,
        library_id: libraryId,
      },
      successType: LOADED_CURRENT_LOANS_LEFT,
    },
  };
}
