import * as ActionType from './libLoginActions';
import Immutable from 'immutable';
import { setCsrfTokenCookie } from 'utils/cookieUtils';

let defaultState = Immutable.fromJS({
  attemptedLoginResult: {},
  attemptedLogoutResult: {},
  librarian: null, // null means not logged in
});

export default function libLoginReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.SET_LIBRARIAN_DATA:
      return state.merge({ librarian: action.librarianData });

    case ActionType.LOGGED_IN:
      return state.merge({ attemptedLoginResult: action.response });

    case ActionType.LOGGED_OUT:
      if (action.response && action.response.success) {
        setCsrfTokenCookie(action.response.new_csrftoken);
        return state.merge({
          attemptedLogoutResult: action.response,
          // reset librarian data and possible previous login attempts if librarian wants to re-login
          librarian: null,
          attemptedLoginResult: {},
        });
      }
      return state.merge({ attemptedLogoutResult: action.response });

    case ActionType.LOADED_LIBRARIAN_DATA:
      return state.merge({
        librarian: action.response && action.response.success ? action.response.librarian : null,
      });

    default:
      return state;
  }
}
