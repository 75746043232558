export default function modalReducers(state = { modal: null }, action) {
  switch (action.type) {
    case 'MODAL_CLOSED':
      return { modal: null };
    case 'MODAL_LOGIN_SHOWN':
      return { modal: 'login' };
    case 'MODAL_REGISTER_SHOWN':
      return { modal: 'register' };
    case 'MODAL_FORGOT_PASSWORD_SHOWN':
      return { modal: 'forgotPassword' };
    case 'MODAL_ADD_LIBRARY_CARD_SHOWN':
      return { modal: 'addLibraryCard' };
    default:
      return state;
  }
}
