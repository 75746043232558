import { CALL_API } from 'middlewares/apiMiddleware';

export const LOADED_FAVOURITE_TITLES = Symbol('LOADED_FAVOURITE_TITLES');
export const LOADED_LOANED_TITLES = Symbol('LOADED_LOANED_TITLES');
export const FAVOURITE_TITLE_REMOVED = Symbol('FAVOURITE_TITLE_REMOVED');

export function loadFavouriteTitles() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/user/favourite/fetch_favourite',
      successType: LOADED_FAVOURITE_TITLES,
    },
  };
}

export function loadLoanedTitles() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/customer/loan/get_auth_user_past_loans',
      successType: LOADED_LOANED_TITLES,
    },
  };
}
