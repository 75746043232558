import superAgent from 'superagent';
import cookie from 'react-cookie';
import { API_ORIGIN, PORTAL_NAME } from 'common/constants';
import { getCurrentLanguage } from './i18nUtils';

let lastLoanRequestDate = null; // used to avoid more than 1 loan within a short delay (dirty fix of weird bug, shame!)

export const createGetUrl = (path, query = []) => {
  return (
    API_ORIGIN +
    path +
    '?' +
    (query.length ? query.join('&') : '') +
    (query.length ? '&' : '') +
    'portal_sessionid=' +
    cookie.load(PORTAL_NAME + '_sessionid')
  );
};

export const createSuperAgent = (params) => {
  let url;
  if (params.externalUrl) {
    url = params.externalUrl;
  } else {
    if (params.path === '/customer/customer/loan') {
      if (lastLoanRequestDate && lastLoanRequestDate.getTime() + 2000 > new Date().getTime()) {
        // 2 seconds delay
        return null;
      }
      lastLoanRequestDate = new Date();
    }

    url = API_ORIGIN + params.path;

    if (params.method && !/^(get|head|options|trace)$/.test(params.method.toLowerCase())) {
      // non-safe requests
      // pass sessionid and csrftoken as POST parameter for non-safe requests
      if (cookie.load(PORTAL_NAME + '_sessionid')) {
        params.body = params.body || {};
        params.body.portal_sessionid = cookie.load(PORTAL_NAME + '_sessionid');
      }
      if (cookie.load(PORTAL_NAME + '_csrftoken')) {
        params.body = params.body || {};
        params.body.portal_csrftoken = cookie.load(PORTAL_NAME + '_csrftoken');
      }
    } else {
      // safe requests
      if (!params.method || params.method.toLowerCase() === 'get') {
        // only pass sessionid for GET requests
        if (cookie.load(PORTAL_NAME + '_sessionid')) {
          params.query = params.query || {};
          params.query.portal_sessionid = cookie.load(PORTAL_NAME + '_sessionid');
        }
      }
    }
  }

  let agent = superAgent[params.method || 'get'](url)
    .set('Accept', 'application/json')
    .send(params.body)
    .type('form')
    .query(params.query);

  if (params.withCredentials) {
    agent = agent.withCredentials();
  }

  if (params.headers) {
    Object.keys(params.headers).forEach((key) => {
      agent = agent.set(key, params.headers[key]);
    });
  }

  // Used for getting the correct cached session data (librarian or customer?) on the backend
  agent.set('X-App-Location', window.location.href);

  // Used for getting proper translations and related data
  agent.set('X-Cineasterna-Language', getCurrentLanguage());

  return agent;
};

// treating the response (res) after a request has been made with the superAgent library
export const getResponseContent = (res) => {
  let responseContent;
  if (res && res.text !== '') {
    // if res.body then some JSON has already been parsed and set in res.body
    if (res.body) {
      responseContent = res.body;
    } else {
      // else we need to parse the JSON from the textual response
      try {
        responseContent = JSON.parse(res.text);
      } catch (e) {
        // in case the JSON could not be parsed (i.e., not proper JSON), then we set
        // the response content to a default JS object and show an error
        responseContent = {};
      }
    }
  }
  return responseContent;
};
