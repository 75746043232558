/*
 * Common constants
 */
import { isIOS, isIOS13, isMobile, isChrome, isSafari, isFirefox, isIE, isEdgeChromium, isEdge } from 'react-device-detect';

export const API_ORIGIN = process.env.REACT_APP_API_ORIGIN || 'http://localhost:3000';
export const LANGUAGE_SELECTOR_ENABLED =
  window.location.protocol === 'http:' || process.env.REACT_APP_LANGUAGE_SELECTOR_ENABLED === 'yes';

export const IOS_NATIVE_APP_ID = process.env.REACT_APP_IOS_NATIVE_APP_ID;

export const CHROMECAST_APPLICATION_ID = process.env.REACT_APP_CHROMECAST_APPLICATION_ID || '489BEC53';

export const HIDE_UPCOMING_TITLES_LIBRARY_ID = parseInt(process.env.REACT_APP_HIDE_UPCOMING_TITLES_LIBRARY_ID);

export const CHILDMODE_MANDATORY_GENRE_ID = parseInt(process.env.REACT_APP_CHILDMODE_MANDATORY_GENRE_ID || 19);

export const AVAILABLE_LANGUAGES = (process.env.REACT_APP_AVAILABLE_LANGUAGES || 'sv,en').trim().split(',');

export const AVAILABLE_LANGUAGES_NO = (process.env.REACT_APP_AVAILABLE_LANGUAGES_NO || 'no,en').trim().split(',');

export const AVAILABLE_LANGUAGES_FI = (process.env.REACT_APP_AVAILABLE_LANGUAGES_FI || 'fi,sv,en').trim().split(',');

export const LOC_LAST_WATCH_TIMES = 'LOC_LAST_WATCH_TIMES';

export const LOC_EVER_LOGGED_IN_AS_LIBRARIAN = 'EVER_LOGGED_IN_AS_LIBRARIAN';

export const LOC_LAST_USED_LOAN_CREDENTIALS = 'LOC_LAST_USED_LOAN_CREDENTIALS';

export const LOC_LAST_SELECTED_LIBRARY = 'LOC_LAST_SELECTED_LIBRARY';

export const DEFAULT_TITLE_IMAGE = 'https://d3k9jeyu3pq1ex.cloudfront.net/static/frontend/default-title-image.png';

export const SOCIAL_MEDIA_LINKS = {
  se: {
    facebook: 'https://www.facebook.com/cineasterna/',
    instagram: 'https://www.instagram.com/cineasterna.se/',
    twitter: 'https://twitter.com/cineasterna/',
  },
  no: {
    facebook: 'https://www.facebook.com/CineastNo/',
    instagram: 'https://www.instagram.com/cineast.no/',
    twitter: 'https://twitter.com/CineastNo/',
  },
  fi: {
    facebook: 'https://www.facebook.com/CineastFi/',
    instagram: 'https://www.instagram.com/cineast.fi',
    twitter: 'https://twitter.com/CineastFi',
  },
};

export const EMAIL_LINKS = {
  se: {
    email: 'info@cineasterna.se',
  },
  no: {
    email: 'info@cineast.no',
  },
  fi: {
    email: 'tuki@cineast.fi',
  },
};

export const PRIVACY_POLICY_PDFS_BROCHURE = {
  se: {
    pdfs: 'https://d3tpb0k5cvtuji.cloudfront.net/static/frontend/personuppgiftspolicy.pdf',
    brochure: 'https://d3k9jeyu3pq1ex.cloudfront.net/static/frontend/Plakat.pdf',
  },
  no: {
    pdfs: 'https://d3k9jeyu3pq1ex.cloudfront.net/static/frontend/Privacy_policy.pdf',
    brochure: 'https://d3k9jeyu3pq1ex.cloudfront.net/static/frontend/Plakat.pdf',
  },
  fi: {
    pdfs: 'https://d3k9jeyu3pq1ex.cloudfront.net/static/frontend/Privacy_policy.pdf',
    brochure: 'https://d3k9jeyu3pq1ex.cloudfront.net/static/frontend/Esite.pdf',
  },
};

export const IS_MOBILE = isMobile;

export const IS_IPHONE_OR_IPAD = isIOS || isIOS13;

const isChromeForIOS = isChrome && IS_IPHONE_OR_IPAD;

export { isChromeForIOS, isChrome, isEdgeChromium, isEdge, isSafari, isFirefox, isIE };

export const IS_COMPATIBLE = (isSafari || isChrome || isEdge || isEdgeChromium || isFirefox || isIE) && (window.Hls.isSupported() || isSafari);

export const ENABLE_LOGIN_USERNAME_FIELD = true;

export const IS_LIBRARIAN_PORTAL = window.location.pathname.split('/')[2] === 'lib'; // /[language code]/lib/...
export const IS_CUSTOMER_PORTAL = !IS_LIBRARIAN_PORTAL;
export const PORTAL_NAME = IS_LIBRARIAN_PORTAL ? 'librarian_portal' : 'customer_portal';
