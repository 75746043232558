import { CALL_API } from 'middlewares/apiMiddleware';
import { getCurrentCountry } from '../../utils/i18nUtils';

export const LOADED_SEARCH_TITLES = Symbol('LOADED_SEARCH_TITLES');

export function searchLibraryTitles({ libraryId, locale, page = 1, search = '' }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/library/title/get_titles',
      query: {
        library_id: libraryId,
        country_iso: getCurrentCountry(),
        page,
        locale,
        sort: 'asc',
        search,
        genres: '[]',
        languages: '[]',
        years: '[]',
        ratings: '[]',
      },
      successType: LOADED_SEARCH_TITLES,
    },
    isFirstPage: page === 1,
    searchIsEmpty: search === '',
  };
}
