import Promise from 'bluebird';
import { resetError, setError } from 'common/err/errorActions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { createSuperAgent, getResponseContent } from 'utils/requestUtils';

export const CALL_API = Symbol('CALL_API');
export const CHAIN_API = Symbol('CHAIN_API');

const apiMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action[CALL_API]) {
      return dispatch({
        [CHAIN_API]: [() => action],
      });
    }

    if (!action[CHAIN_API]) {
      return next(action);
    }

    const promiseCreators = action[CHAIN_API].map((apiActionCreator) => {
      return createRequestPromise(apiActionCreator, next, getState, dispatch)();
    });

    return Promise.all(promiseCreators).catch((err) => {
      console.error(err);
      throw err;
    });
  };

export default apiMiddleware;

function actionWith(apiAction, toMerge) {
  let action = Object.assign({}, apiAction, toMerge);
  delete action[CALL_API];
  return action;
}

function createRequestPromise(apiActionCreator, next, getState, dispatch) {
  return (prevBody) => {
    const apiAction = apiActionCreator(prevBody);
    const params = apiAction[CALL_API];

    if (!params.disableLoaderIndicator) {
      dispatch(showLoading());
    }

    return new Promise((resolve, reject) => {
      const agent = createSuperAgent(params);
      if (!agent) {
        reject();
      } else {
        dispatch({
          type: 'NETWORK_PENDING',
          origin: params.successType,
        });
        agent.end((err, res) => {
          if (!params.disableLoaderIndicator) {
            dispatch(hideLoading());
          }

          if (!res || err) {
            if (params.errorType) {
              dispatch(
                actionWith(apiAction, {
                  type: params.errorType,
                  response: getResponseContent(res),
                  error: err,
                })
              );
            }
            if (typeof params.afterError === 'function') {
              params.afterError({ getState });
            }

            dispatch({
              type: 'NETWORK_ERROR',
              origin: params.successType,
              err,
            });

            // Dispatching error to the global error reducer, if not errorExempt = true
            if (!params.errorExempt) {
                if (res) {
                dispatch(setError(err, params, res));
                } else {
                dispatch(setError(err, params));
                }
            }

            reject();
          } else {
            let responseContent = getResponseContent(res);

            dispatch(
              actionWith(apiAction, {
                type: params.successType,
                response: responseContent,
              })
            );

            if (typeof params.afterSuccess === 'function') {
              params.afterSuccess({ getState });
            }

            dispatch({
              type: 'NETWORK_SUCCESS',
              origin: params.successType,
            });

            // Resetting global error state
            dispatch(resetError());

            resolve(responseContent);
          }
        });
      }
    });
  };
}
