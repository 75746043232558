import * as ActionType from './titleActions';
import Immutable from 'immutable';

let defaultState = Immutable.fromJS({
  titleData: {
    title: {},
    subtitle_languages: {},
    is_favourite: false,
  },
  currentLoansLeft: null,
  currentLoansLeftPeriod: 'monthly',
  isFavourite: false,
  suggestions: [],
  groupData: {},
  loanData: {},
  error: null,
});

export default function titleReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOADED_TITLE:
      return state.merge({
        titleData: action.response,
        groupData: {},
        isFavourite: action.response.is_favourite,
      });

    case ActionType.LOADED_CURRENT_LOANS_LEFT:
      return state.merge({
        currentLoansLeft: (action.response && action.response.num_loans_left) || null,
        currentLoansLeftPeriod: (action.response && action.response.num_loans_left_period) || 'monthly',
      });

    case ActionType.LOADED_SIMILAR_TITLES:
        const { suggestions } = action.response;
        return state.merge({ suggestions });

    case ActionType.LOADED_GROUP_TITLES:
      return state.merge({ groupData: action.response });

    case ActionType.TITLE_LOANED:
      return state.merge({ loanData: action.response });

    case ActionType.TITLE_LOANED_ERROR:
      return state.merge({
        loanData: action.response,
        error: action.error,
      });

    case ActionType.CLEARED_LOAN_INFORMATION:
      return state.merge({
        loanData: {},
        error: null,
      });

    case ActionType.TITLE_FAVOURITED:
      return state.merge({ isFavourite: true });

    case ActionType.TITLE_UNFAVOURITED:
      return state.merge({ isFavourite: false });

    default:
      return state;
  }
}

export function favouriteTitleInArray(titles, action) {
  if (!titles) return titles;
  return titles.map((title) => {
    if (title.get('id') !== action.response.data.title_id || !action.response.success) {
      return title;
    } else {
      return title.set('is_favourite', action.response.data.id);
    }
  });
}

export function unfavouriteTitleInArray(titles, action) {
  if (!titles) return titles;
  return titles.map((title) => {
    if (title.get('id') !== action.response.data.title_id || !action.response.success) {
      return title;
    } else {
      return title.set('is_favourite', undefined);
    }
  });
}
