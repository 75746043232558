import { CALL_API } from 'middlewares/apiMiddleware';
import { getCurrentCountry } from 'utils/i18nUtils';

export const LOADED_LIBRARIES = Symbol('LOADED_LIBRARIES');
export const LOADED_NEW_TITLES = Symbol('LOADED_NEW_TITLES');
export const LOADED_GET_STARTED_STEPS = Symbol('LOADED_GET_STARTED_STEPS');

export function loadLibraries() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/library/library/get_all_libraries',
      query: {
        country: getCurrentCountry(),
      },
      successType: LOADED_LIBRARIES,
    },
  };
}

export function loadNewTitles() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/library/title/get_new_titles',
      query: {
        country_iso: getCurrentCountry(),
        num_titles: 7,
      },
      successType: LOADED_NEW_TITLES,
    },
  };
}

export function loadGetStartedSteps() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/i18n/getstartedstep/get_all_getstartedsteps',
      successType: LOADED_GET_STARTED_STEPS,
    },
  };
}
