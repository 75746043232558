import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Loader.scss';

class Loader extends Component {
  render() {
    return (
      <div className={'loader-wrapper' + (this.props.smallPadding ? ' small-padding' : '')}>
        <div className={'loader' + (this.props.inverted ? ' inverted' : '')} />
      </div>
    );
  }
}

Loader.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
  smallPadding: PropTypes.bool,
};

export default Loader;
