// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See https://mxstbr.blog/2016/01/react-apps-with-pages/ for more information
// about the code splitting business

import { IS_LIBRARIAN_PORTAL } from 'common/constants';

const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
};

const loadModule = (cb) => (componentModule) => {
  cb(null, componentModule.default);
};

const customerPortalRoutes = [
  {
    path: '/:i18nLanguage/',
    name: 'selectLibrary',
    getComponent(nextState, cb) {
      const importModules = Promise.all([import('customer/SelectLibrary/SelectLibraryContainer')]);

      const renderRoute = loadModule(cb);
      importModules.then(([component]) => {
        renderRoute(component);
      });

      importModules.catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/library/:libraryId/all',
    name: 'all',
    getComponent(nextState, cb) {
      import('customer/AllTitles/AllTitlesContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/library/:libraryId/selected',
    name: 'selected',
    getComponent(nextState, cb) {
      import('customer/SelectedTitles/SelectedTitlesContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/library/:libraryId/search',
    name: 'search',
    getComponent(nextState, cb) {
      import('customer/SearchTitles/SearchTitlesContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/library/:libraryId/title/:titleId',
    name: 'title',
    getComponent(nextState, cb) {
      import('customer/Title/TitleContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/watch-as-admin/title/:titleId',
    name: 'titleAsAdmin',
    getComponent(nextState, cb) {
      import('customer/Title/TitleContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/watch-as-special/title/:titleId',
    name: 'titleAsSpecial',
    getComponent(nextState, cb) {
      import('customer/Title/TitleContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/watch-as-librarian/title/:titleId',
    name: 'titleAsLibrarian',
    getComponent(nextState, cb) {
      import('customer/Title/TitleContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/transition',
    name: 'transition',
    getComponent(nextState, cb) {
      import('customer/Transition/TransitionContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/settings/change-password(/:actionParam)',
    name: 'passwordReset',
    getComponent(nextState, cb) {
      import('customer/PasswordReset/PasswordResetContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/settings(/:action)(/:actionParam)',
    name: 'userSettings',
    getComponent(nextState, cb) {
      import('customer/UserSettings/UserSettingsContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/profile',
    name: 'userProfile',
    getComponent(nextState, cb) {
      import('customer/UserProfile/UserProfileContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/:unknownPath',
    name: 'notfound',
    getComponent(nextState, cb) {
      import('customer/NotFoundPage').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '*',
    name: 'notfound',
    getComponent(nextState, cb) {
      import('customer/NotFoundPage').then(loadModule(cb)).catch(errorLoading);
    },
  },
];

// all path must start with /[LANGUAGE CODE]/lib/
const librarianPortalRoutes = (notFoundPath = '/:i18nLanguage/lib/*') => [
  {
    path: '/:i18nLanguage/lib/login(/:next)',
    name: 'libLogin',
    getComponent(nextState, cb) {
      const importModules = Promise.all([import('librarian/LibLogin/LibLoginContainer')]);

      const renderRoute = loadModule(cb);
      importModules.then(([component]) => {
        renderRoute(component);
      });

      importModules.catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/lib/',
    name: 'libStatistics',
    getComponent(nextState, cb) {
      import('librarian/LibStatistics/LibStatisticsContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/lib/settings(/:action)',
    name: 'libSettings',
    getComponent(nextState, cb) {
      import('librarian/LibSettings/LibSettingsContainer').then(loadModule(cb)).catch(errorLoading);
    },
  },
  {
    path: '/:i18nLanguage/lib/catalogue',
    name: 'libCatalogue',
    getComponent(nextState, cb) {
      const importModules = Promise.all([import('librarian/LibCatalogue/LibCatalogueContainer')]);

      const renderRoute = loadModule(cb);
      importModules.then(([component]) => {
        renderRoute(component);
      });

      importModules.catch(errorLoading);
    },
  },
  {
    path: notFoundPath,
    name: 'notfound',
    getComponent(nextState, cb) {
      import('librarian/LibNotFoundPage').then(loadModule(cb)).catch(errorLoading);
    },
  },
];

export default function createRoutes(store) {
  if (IS_LIBRARIAN_PORTAL) {
    return librarianPortalRoutes('*');
  }
  // else customer portal
  return customerPortalRoutes;

  // In case all paths have to be allowed whatever the portal is, comment the above and uncomment the following.
  // const notFoundRoute = customerPortalRoutes.pop();
  // return customerPortalRoutes.concat(librarianPortalRoutes()).concat(notFoundRoute);
}
