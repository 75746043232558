import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from './modalActions';
import { i18n } from 'utils/i18nUtils';
import ReactModal from 'react-modal';
import CloseIcon from 'common/assets/icons/CloseIcon';
import LogIn from 'customer/Auth/components/Login/Login';
import Register from 'customer/Auth/components/Register/Register';
import ForgotPassword from 'customer/Auth/components/ForgotPassword/ForgotPassword';
import AddLibraryCard from 'customer/UserSettings/components/LibraryCards/AddLibraryCard';
import './Modal.scss';

ReactModal.setAppElement('#app');

class Modal extends Component {
  constructor(props) {
    super(props);
    this.content = {
      login: { title: () => i18n('LOG_IN'), content: <LogIn /> },
      register: {
        title: () => i18n('REGISTER'),
        content: (
          <Register
            showLoginLink
            infoText={i18n('REGISTER_INFO')}
            submitButtonText={i18n('REGISTER')}
            successText={i18n('ACCOUNT_CREATED')}
            onDone={props.closeModal}
          />
        ),
      },
      forgotPassword: { title: () => i18n('FORGOT_PASSWORD'), content: <ForgotPassword /> },
      addLibraryCard: { title: () => i18n('ADD_LIBRARY_CARD'), content: <AddLibraryCard /> },
    };
  }

  render() {
    if (!this.props.modal) {
      return null;
    }

    return (
      <ReactModal
        overlayClassName="modal-overlay"
        className="modal"
        isOpen={!!this.props.modal}
        onRequestClose={this.props.closeModal}
        contentLabel={this.content[this.props.modal].title()}
        shouldCloseOnOverlayClick={true}
      >
        <div className="title-bar">
          <p className="title">{this.content[this.props.modal].title()}</p>
          <div className="close-icon" onClick={this.props.closeModal}>
            <CloseIcon />
          </div>
        </div>
        {this.content[this.props.modal].content}
      </ReactModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    modal: state.get('modalReducers').modal,
  };
}

export default connect(mapStateToProps, {
  closeModal,
})(Modal);
