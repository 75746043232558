import * as ActionType from './libStatisticsActions';
import Immutable from 'immutable';

let defaultState = Immutable.fromJS({
  extraStatisticInfo: {},
});

export default function libStatisticsReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOAD_EXTRA_STATISTIC_INFO:
      return state.merge({ extraStatisticInfo: action.response });

    default:
      return state;
  }
}
