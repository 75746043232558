import { CALL_API } from 'middlewares/apiMiddleware';

export const LOADED_LIBRARIAN_TITLES = Symbol('LOADED_LIBRARIAN_TITLES');

export function loadLibrarianTitles() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/librarian/librarian/get_librarian_titles',
      successType: LOADED_LIBRARIAN_TITLES,
    },
  };
}
