import React from 'react';

function CardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path d="M 0 0 L 24 0 L 24 24 L 0 24 Z" fill="transparent"></path>
      <path d="M 18 8 L 17 8 L 17 6 C 17 3.24 14.76 1 12 1 C 9.24 1 7 3.24 7 6 L 7 8 L 6 8 C 4.9 8 4 8.9 4 10 L 4 20 C 4 21.1 4.9 22 6 22 L 18 22 C 19.1 22 20 21.1 20 20 L 20 10 C 20 8.9 19.1 8 18 8 Z M 12 17 C 10.9 17 10 16.1 10 15 C 10 13.9 10.9 13 12 13 C 13.1 13 14 13.9 14 15 C 14 16.1 13.1 17 12 17 Z M 15.1 8 L 8.9 8 L 8.9 6 C 8.9 4.29 10.29 2.9 12 2.9 C 13.71 2.9 15.1 4.29 15.1 6 Z"></path>
    </svg>
  );
}

export default CardIcon;
