import React, { Component } from 'react';
import { connect } from 'react-redux';
import { i18n } from 'utils/i18nUtils';
import { resetNetworkStatus } from 'middlewares/networkActions';
import { userLogin, LOGIN_ATTEMPTED } from '../../authActions';
import { showRegisterModal, showForgotPasswordModal } from 'customer/App/components/Modal/modalActions';
import Button from 'common/components/Button/Button';
import Input from 'common/components/Input/Input';
import EmailIcon from 'common/assets/icons/EmailIcon';
import LockIcon from 'common/assets/icons/LockIcon';
import './Login.scss';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      password: null,
    };

    this.handleOnChangeEmail = this.handleOnChangeEmail.bind(this);
    this.handleOnChangePassword = this.handleOnChangePassword.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    this.props.resetNetworkStatus(LOGIN_ATTEMPTED);
  }

  handleOnChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  handleOnChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  login(e) {
    e.preventDefault();
    this.props.userLogin(this.state.email, this.state.password);
  }

  render() {
    return (
      <form className="login-form" onSubmit={this.login} noValidate>
        <div className="login-content">
          <p className="login-info">{i18n('LOGIN_BOX_TEXT')}</p>
          <p className="account-question">
            <span>{i18n('NO_ACCOUNT_QUESTION')} </span>
            <Button variant="link" size="md" modifier="action" onClick={this.props.showRegisterModal}>
              {i18n('CREATE_ACCOUNT_HERE')}
            </Button>
          </p>
          <Input
            className="login-input"
            type="email"
            placeholder={i18n('EMAIL')}
            icon={EmailIcon}
            value={this.state.email}
            onChange={this.handleOnChangeEmail}
          />
          <Input
            className="login-input"
            type="password"
            placeholder={i18n('PASSWORD')}
            icon={LockIcon}
            value={this.state.password}
            onChange={this.handleOnChangePassword}
            errorMessage={this.props.loginRequestStatus.error ? i18n('WRONG_USERNAME_OR_PASSWORD') : null}
          />
          <Button variant="link" size="sm" onClick={this.props.showForgotPasswordModal}>
            {i18n('FORGOT_PASSWORD_QUESTION')}
          </Button>
        </div>
        <Button className="submit-button" type="submit" disabled={this.props.loginRequestStatus.pending}>
          {i18n('LOG_IN')}
        </Button>
      </form>
    );
  }
}

Login.defaultProps = {
  loginRequestStatus: {},
};

function mapStateToProps(state) {
  return {
    loginRequestStatus: state.get('networkReducers')[LOGIN_ATTEMPTED],
  };
}

export default connect(mapStateToProps, {
  showRegisterModal,
  showForgotPasswordModal,
  userLogin,
  resetNetworkStatus,
})(Login);
