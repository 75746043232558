import { CALL_API } from 'middlewares/apiMiddleware';
import { getCurrentCountry } from '../../utils/i18nUtils';

export const LOADED_THEMES = Symbol('LOADED_THEMES');
export const LOADED_ALL_THEME_TITLES = Symbol('LOADED_THEME');
export const LOADED_ALL_SELECTED_TITLES = Symbol('LOADED_THEME');
export const LOADED_SELECTED_TITLES = Symbol('LOADED_SELECTED_TITLES');
export const SET_SEE_ALL_TITLES = Symbol('SET_SEE_ALL_TITLES');

export function loadSelectedTitles({ libraryId }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/library/title/get_selected_titles',
      query: {
        library_id: libraryId,
        country_iso: getCurrentCountry(),
        num_titles: 10,
      },
      successType: LOADED_SELECTED_TITLES,
    },
  };
}

export function loadThemes({ libraryId, page }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/library/theme/get_themes',
      query: {
        library_id: libraryId,
        country_iso: getCurrentCountry(),
        page,
        num_titles: 10,
      },
      successType: LOADED_THEMES,
    },
    isFirstPage: page === 1,
  };
}

export function seeAllThemeTitles({ themeId, libraryId }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/library/theme/get_theme',
      query: {
        theme_id: themeId,
        library_id: libraryId,
        country_iso: getCurrentCountry(),
        num_titles: 50,
      },
      successType: LOADED_ALL_THEME_TITLES,
    },
  };
}

function fetchSelectedTitles({ libraryId, heading }, endpoint) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/library/title/${endpoint}`,
      query: {
        library_id: libraryId,
        country_iso: getCurrentCountry(),
        num_titles: 50,
      },
      successType: LOADED_ALL_SELECTED_TITLES,
    },
    heading,
  };
}

export function seeAllNewTitles(props) {
  return fetchSelectedTitles(props, 'get_new_titles');
}

export function seeAllSelectedByLibraryTitles(props) {
  return fetchSelectedTitles(props, 'get_selected_titles_by_library');
}

export function setSeeAllTitles({ titles, heading }) {
  return { titles, heading, type: SET_SEE_ALL_TITLES };
}
