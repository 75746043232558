import { CALL_API } from 'middlewares/apiMiddleware';

export const LOGGED_IN = Symbol('LOGGED_IN');
export const LOGGED_OUT = Symbol('LOGGED_OUT');
export const SET_LIBRARIAN_DATA = Symbol('SET_LIBRARIAN_DATA');
export const LOADED_LIBRARIAN_DATA = Symbol('LOADED_LIBRARIAN_DATA');

export function logIn(username, password) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/librarian/librarian/login',
      body: {
        username: username,
        password: password,
      },
      successType: LOGGED_IN,
    },
  };
}

export function logOut() {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/librarian/librarian/logout',
      successType: LOGGED_OUT,
    },
  };
}

export function setLibrarianData(librarianData) {
  return {
    librarianData: librarianData,
    type: SET_LIBRARIAN_DATA,
  };
}

export function loadLibrarianData() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/librarian/librarian/get_librarian_data',
      successType: LOADED_LIBRARIAN_DATA,
    },
  };
}
