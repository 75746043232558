import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { i18n, getUrl, getCurrentCountry } from 'utils/i18nUtils';
import './footer.scss';
import help_icon from './assets/help_icon.png';
import { SOCIAL_MEDIA_LINKS, PRIVACY_POLICY_PDFS_BROCHURE, EMAIL_LINKS } from 'common/constants';

export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breakpoint: Footer.getBreakpoint(window.innerWidth),
    };

    window.addEventListener('resize', () => {
      this.setState({ breakpoint: Footer.getBreakpoint(window.innerWidth) });
    });
  }

  static getBreakpoint(width) {
    let bp = 'xs';
    if (width >= 0) bp = 'xs';
    if (width >= 576) bp = 'sm';
    if (width >= 768) bp = 'md';
    if (width >= 992) bp = 'lg';
    if (width >= 1200) bp = 'xl';
    return bp;
  }

  getSocialMediaLinks() {
    switch (getCurrentCountry()) {
      case 'no':
        return SOCIAL_MEDIA_LINKS.no;
      case 'fi':
        return SOCIAL_MEDIA_LINKS.fi;
      default:
        return SOCIAL_MEDIA_LINKS.se;
    }
  }

  getPrivacyPolicyPdfs() {
    switch (getCurrentCountry()) {
      case 'no':
        return PRIVACY_POLICY_PDFS_BROCHURE.no;
      case 'fi':
        return PRIVACY_POLICY_PDFS_BROCHURE.fi;
      default:
        return PRIVACY_POLICY_PDFS_BROCHURE.se;
    }
  }

  getEmailLinks() {
    switch (getCurrentCountry()) {
      case 'no':
        return EMAIL_LINKS.no;
      case 'fi':
        return EMAIL_LINKS.fi;
      default:
        return EMAIL_LINKS.se;
    }
  }

  render() {
    const { countryConfig } = this.props;

    const privacyPolicyData = this.getPrivacyPolicyPdfs();
    const privacyPolicyLink = privacyPolicyData.pdfs;

    const emailData = this.getEmailLinks();
    const emailLink = emailData.email;

    let bp = this.state.breakpoint;

    const helpUrl = getUrl(countryConfig, 'help');

    return (
      <div className="footer-container">
        <div className="container">
          <div className="footer-info">
            {process.env.NODE_ENV === 'development' && (
              <p style={{ position: 'fixed', left: '10px', bottom: '0', backgroundColor: 'red' }}>
                Active Breakpoint: {bp}
              </p>
            )}
            <p>
              <a href="http://www.forlagett.se" target="_blank" rel="noopener noreferrer">
                FörlagEtt AB{' '}
              </a>
              {i18n('CINEASTERNA_MADE_BY')}
            </p>

            <div className="row-wrapper">
              <p>
                {i18n('CONTACT')}: <a href={`mailto:${emailLink}`}>{emailLink}</a>
              </p>

              <p className="separator">|</p>

              <p>
                <a href={getUrl(countryConfig, 'press')} target="_blank" rel="noopener noreferrer" className="link">
                  {i18n('PRESS')}
                </a>
              </p>

              <p className="separator">|</p>

              <p>
                <a href={getUrl(countryConfig, 'help')} target="_blank" rel="noopener noreferrer" className="link">
                  {i18n('HELP')}
                </a>
              </p>
            </div>

            <div className="row-wrapper">
              <p
                dangerouslySetInnerHTML={{
                  __html: i18n('FOLLOW_US')
                    .replace(
                      '{FACEBOOK}',
                      `<a href="${
                        this.getSocialMediaLinks().facebook
                      }" target="_blank" rel="noopener noreferrer">Facebook</a>`
                    )
                    .replace(
                      '{INSTAGRAM}',
                      `<a href="${
                        this.getSocialMediaLinks().instagram
                      }" target="_blank" rel="noopener noreferrer">Instagram</a>`
                    )
                    .replace(
                      '{TWITTER}',
                      `<a href="${
                        this.getSocialMediaLinks().twitter
                      }" target="_blank" rel="noopener noreferrer">X (Twitter)</a>`
                    ),
                }}
              ></p>
            </div>

            <p>
              <a
                href={getUrl(countryConfig, 'default')}
                target="_blank"
                rel="noopener noreferrer"
                className="link md-screen"
              >
                {i18n('FOR_LIBRARY')}
              </a>
            </p>
          </div>

          <a href={helpUrl} target="_blank" rel="noopener noreferrer" className="footer-help">
            <img alt={i18n('HELP')} src={help_icon} />
          </a>

          <div className="footer-privacy">
            {i18n('FIND_PRIVACY_POLICY')}:&nbsp;
            <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
              {i18n('PRIVACY_POLICY')}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  config: PropTypes.instanceOf(Map),
  countryConfig: PropTypes.instanceOf(Map),
};
