import React from 'react';
import ReactDOM from 'react-dom';

export default class Portal extends React.Component {
  componentDidUpdate() {
    this._renderLayer();
  }

  componentDidMount() {
    this._renderLayer();
  }

  componentWillUnmount() {
    if (document.getElementById(this.props.container)) {
      ReactDOM.unmountComponentAtNode(document.getElementById(this.props.container));
    }
  }

  _renderLayer() {
    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      this.props.children,
      document.getElementById(this.props.container)
    );
  }

  render() {
    return null;
  }
}
